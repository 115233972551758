import Heading from "../../components/heading/Heading.jsx";
import PricingPlan from "../../components/pages/plans/PricingPlan.jsx";

const PlanPage = () => {
  return (
    <section className="py-10">
      <div className="container">
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-center text-center max-w-[650px] mx-auto">
            <Heading
              // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo."
              title="Pricing"
            />
          </div>
        </div>
        <div>
          <PricingPlan />
        </div>
      </div>
    </section>
  );
};

export default PlanPage;
