import CancelButton from "./CancelButton";
import EditButton from "./EditButton";
import RemoveButton from "./RemoveButton";

const ActionButton = props => {
	const { view, edit, remove, cancel, viewResponse } = props;

	return (
		<>
			<div className="btn-action flex items-center justify-center gap-0">
				{edit && <EditButton edit={edit} />}
				{remove && <RemoveButton remove={remove} />}
				{cancel && <CancelButton cancel={cancel} />}
			</div>
		</>
	);
};

export default ActionButton;
