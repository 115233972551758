import { Text } from "@mantine/core";

const TableHeading = ({ heading }) => {
  return (
    <div className="inline-flex items-center">
      <Text lineClamp={1} component="h1" fz="xl" fw="bold">
        {heading}
      </Text>
    </div>
  );
};

export default TableHeading;
