import React, { useState } from 'react'
import { useGetHoldingListQuery } from '../../../services/account/broker.service';
import { Table, Text } from '@mantine/core';
import dayjs from 'dayjs';

const HoldingsList = ({accountData}) => {
    const { data: holdingList } = useGetHoldingListQuery(accountData?.id, {skip: !accountData?.id});

    const [scrolled, setScrolled] = useState(false);

    const sum = holdingList?.data?.holdings?.reduce((total, item) => +total + +item.profitandloss, 0);
    
    const getSum = () => {
        if (sum > 0) {
            return <p className='text-green-500 font-bold'>{sum}</p>
        } else {
            return <p className='text-red-500 font-bold'>{sum}</p>
        }
    }
    
    const rows = holdingList?.data?.holdings?.map((order) => (
      <Table.Tr key={order?.orderid}>
        <Table.Td>{order?.tradingsymbol}</Table.Td>
        <Table.Td ta="center">{order?.quantity}</Table.Td>
        <Table.Td ta="center">{order?.averageprice}</Table.Td>
        <Table.Td ta="center">{order?.ltp}</Table.Td>
        <Table.Td ta="center">{order?.profitandloss}</Table.Td>
      </Table.Tr>
    ));
  
    return (
      <div>
        <div className="flex items-center justify-between gap-5 mb-4">
          <Text component="h3" fz={20} lineClamp={1} fw="bold">
          {accountData?.broker_id} - POSITIONS | LAST UPDATE TIME : {dayjs(holdingList?.data?.lastOrder?.updatetime).format('hh:mm:ss A')} 
          </Text>
        </div>
         <Table.ScrollContainer maw={1100} h={400} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
          <Table
            withTableBorder
            withRowBorders
            withColumnBorders
            highlightOnHover
            striped
            verticalSpacing="xs"
            horizontalSpacing="xs"
            stickyHeader
            styles={{
              thead: {
                boxShadow: "var(--mantine-shadow-xs)",
                background: "var(--mantine-color-default)",
                zIndex: 9,
              },
              th: {
                fontSize: "var(--mantine-font-size-sm)",
              },
            }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>TRADING SYMBOL</Table.Th>
                <Table.Th ta="center">QUANTITY</Table.Th>
                <Table.Th ta="center">AVERAGE PRICE</Table.Th>
                <Table.Th ta="center">LAST TRADED PRICE</Table.Th>
                <Table.Th ta="center">PROFIT OR LOSS</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {rows}
                <Table.Tr withRowBorders={false} withColumnBorders={false}>
            <Table.Td ta="center"></Table.Td>
            <Table.Td ta="center"></Table.Td>
            <Table.Td ta="center"></Table.Td>
            <Table.Td ta="center"></Table.Td>
            <Table.Td ta="center">{getSum()}</Table.Td>
        </Table.Tr>
            
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </div>
    )
}

export default HoldingsList
