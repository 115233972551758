import { Link, NavLink } from "react-router-dom";
import { publicRoutes } from "../../routes/public.routes";
import { Button } from "@mantine/core";
import UserMenu from "../user/UserMenu";
import SwitchDashboard from "./SwitchDashboard";
import { authRouteLinks } from "../../routes/auth.routes";
import { GET_AUTH_CONFIG } from "../../redux/auth/config.slice";
import { useSelector } from "react-redux";


const MSidebar = () => {
    const { isLoggedIn } = useSelector(GET_AUTH_CONFIG);
  return (
    <div className="header-menu">
        {isLoggedIn && <div className="flex items-center justify-between gap-5 mb-3">
              <SwitchDashboard />
              <UserMenu />
            </div>}
      {publicRoutes
        .filter((item) => item.path !== "*")
        .map((route) => {
          return (
            <NavLink
              key={route.name}
              className="p-3 block font-bold text-md capitalize"
              to={route.path}
            >
              <div className="flex items-center gap-1">
                <span className="inline-flex items-center justify-center">
                  {route.icon}
                </span>
                <span className="inline-flex items-center justify-center">
                  {route.name}
                </span>
              </div>
            </NavLink>
          );
        })}

        {!isLoggedIn && (
            <div className="px-3 flex items-center justify-center mt-5 gap-3">
              <Button fullWidth size="md" component={Link} to={authRouteLinks.login.path}>
                {authRouteLinks.login.name}
              </Button>
              <Button
              fullWidth
              size="md"
                variant="outline"
                component={Link}
                to={authRouteLinks.register.path}
              >
                {authRouteLinks.register.name}
              </Button>
            </div>
          )}
    </div>
  )
}

export default MSidebar
