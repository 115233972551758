import { useChangeTradeMutation } from "../../../services/account/broker.service";
import { FormProvider, useForm } from "react-hook-form";
import SwitchInput from "../../../forms/elements/SwitchInput.jsx";

const AccountTrade = ({cellData}) => {
  
  const accountData = cellData?.row?.original?.accountDetail;
  
  const [changeTrade] = useChangeTradeMutation();
  
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      trade: accountData?.[0]?.trade === "true",
    },
  });

  const changeTradeSetting = async (value) => {
    await changeTrade({ trade: value.trade, id: accountData?.[0]?.id });
  };

  return (
    <FormProvider {...methods}>
      <form onChange={methods.handleSubmit(changeTradeSetting)}>
        <SwitchInput name="trade" props={{ onLabel: "On", offLabel: "Off" }} />
      </form>
    </FormProvider>
  );
};

export default AccountTrade;
