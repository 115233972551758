import TableActionRightCustom from "./TableActionRightCustom";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";

const TableToolbarInternalActions = ({ id, refetch, rightAction }) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <Tooltip label="Refetch Data">
        <ActionIcon
          id={id}
          variant="default"
          c="gray.6"
          p={5}
          size="lg"
          w={45}
          h={42}
          onClick={() => refetch()}
        >
          <IconRefresh size="1.125rem" />
        </ActionIcon>
      </Tooltip>
      <TableActionRightCustom rightAction={rightAction} />
    </div>
  );
};

export default TableToolbarInternalActions;
