import { Link, Outlet, useLocation } from "react-router-dom";
import { AppShell, Button, Text } from "@mantine/core";
import { useDidUpdate, useDisclosure} from "@mantine/hooks";
import Header from "../components/common/Header.jsx";
import Footer from "../components/common/Footer.jsx";
import MSidebar from "../components/common/MSidebar.jsx";
import { authRouteLinks } from "../routes/auth.routes.jsx";

const PublicLayout = () => {
  const [opened, { toggle }] = useDisclosure();
  const location = useLocation();


  useDidUpdate(() => {
    if(location && opened) {
      toggle()
    }
  }, [location])
  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { desktop: true, mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header className="shadow drop-shadow-md pb-4" withBorder={false}>
        <Header opened={opened} toggle={toggle} />
        <div className="flex align-center justify-center bg-orange-50 py-1">
          <Text fz={14} fw={"bold"}>Sign up now! for 2-Days Free Trial: </Text>
          <Link
            className="ml-2 font-bold text-[14px] text-[var(--mantine-primary-color-filled)]"
            to={authRouteLinks.register.path}
          >
            Sign Up
          </Link>
        </div>
      </AppShell.Header>

      <AppShell.Navbar p="md" px={4} zIndex={0}>
        <MSidebar />
      </AppShell.Navbar>

      <AppShell.Main
        p={0}
        className="bg-gray-50 mt-[var(--app-shell-header-height)]"
      >
        <Outlet />
      </AppShell.Main>
      <Footer />
    </AppShell>
  );
};
export default PublicLayout;
