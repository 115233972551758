import { FormProvider, useForm } from "react-hook-form";
import SwitchInput from "../../../forms/elements/SwitchInput";
import { useChangeAccountQuantityMutation } from "../../../services/account/accounts.service";
import { reFetchTableData1 } from "../../../utils/functions";

const AccountQuantity = (cellData) => {
  const accountDetails = cellData?.rowData?.original;
  const [ChangeAccountQuantity] = useChangeAccountQuantityMutation();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      quantity_type: accountDetails?.quantity_type === "Fixed",
    },
  });

  const changeTradeSetting = async (value) => {
    await ChangeAccountQuantity({
      quantity_type: value.quantity_type ? "Fixed" : "Manual",
      id: accountDetails?.id,
    });
    reFetchTableData1();
  };

  return (
    <FormProvider {...methods}>
      <form onChange={methods.handleSubmit(changeTradeSetting)}>
        <SwitchInput
          name="quantity_type"
          label={`${accountDetails?.quantity} ${
            accountDetails?.quantity_type === "Fixed" ? "Fixed" : "Times"
          }`}
        />
      </form>
    </FormProvider>
  );
};

export default AccountQuantity;
