import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";
import PopupContext from "../../../popup/PoupContext";

const EditButton = ({ edit }) => {
  const {
    icon = "fluent:edit-16-regular",
    label = "",
    isPopup = false,
    iconSize = 16,
  } = edit;
  return (
    <>
      <div
        key={`act-edit-${JSON.stringify(edit.url)}`}
        className={`btn-act ${edit.customClass}`}
      >
        {isPopup ? (
          <PopupContext
            buttonLabel={label}
            buttonIcon={icon}
            modelTitle={edit.popupTitle}
            component={edit.component}
            customClass={`!p-1 w-0 !h-auto  ${edit.customClass}`}
            variant="subtle"
          />
        ) : (
          <Button
            variant="subtle"
            component={Link}
            className={`!p-1 w-0 !h-auto ${edit.customClass}`}
            to={edit.url}
          >
            <Icon icon={icon} width={iconSize} height={iconSize} />
            {label && <span>{label}</span>}
          </Button>
        )}
      </div>
    </>
  );
};
export default EditButton;
