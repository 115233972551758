import { Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { downloadExcelFile } from '../../../services/account/download.service';

const DownloadPnl = ({accountData}) => {
    const downloadAccountList = async () => {
        await downloadExcelFile(`broker/download-child-positions/${accountData?.id}`, 'GET', 'pnl_all.xlsx');
    }
        return (
        <Button variant="light" leftSection={<IconDownload />} onClick={downloadAccountList}>
            Download
        </Button>
    );
}

export default DownloadPnl
