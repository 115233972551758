import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from '@mantine/core';

const TextAreaInput = ({
	name,
	placeholder,
	label,
	props,
	disabled,
	isCharCount,
	maxLength
}) => {
	const {
		formState: { errors },
		control
	} = useFormContext();
	const [charCount, setCharCount] = useState(0);
	const handleCharCount = e => {
		const value = e.target.value;
		setCharCount(value.length);
	};

	return (
		<div>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, onBlur, value, ref } }) => (
					<Textarea
						// maxLength={isCharCount && maxLength}
						ref={ref}
						label={label}
						onBlur={onBlur}
						value={value}
						disabled={disabled}
						onChange={newValue => {
							onChange(newValue);
							isCharCount && handleCharCount(newValue);
						}}
						autosize
						minRows={5}
						placeholder={placeholder ? placeholder : `Enter ${label}`}
						error={!!errors?.[name]}
						{...props}
					/>
				)}
			/>
			<div className="flex justify-between item-center">
				<div>
					{errors?.[name] && (
						<div className="text-[#fb3d3d] text-[13px] mt-1">
							{errors?.[name]?.message}
						</div>
					)}
				</div>
				<div>
					{isCharCount && charCount !== 0 && (
						<div className="char-count text-[13px] mt-1">
							<span className={maxLength < charCount && 'text-theme'}>
								{charCount}
							</span>
							/{maxLength}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TextAreaInput;
