import OrderBookList from "./OrderBookList";
import ConsoleAction from "./ConsoleAction.jsx";
import { IconAdjustments, IconSteeringWheel } from "@tabler/icons-react";
import PositionsList from "./PositionsList.jsx";
import HoldingsList from "./HoldingsList.jsx";

const AccountConsole = (cellData) => {
  return (
    <div className="flex gap-2">
      <ConsoleAction
        component={<OrderBookList accountData={cellData?.rowData?.original} />}
        title="Orderbook"
      />
      <ConsoleAction
        component={<PositionsList accountData={cellData?.rowData?.original} />}
        title="Positions"
        icon={<IconAdjustments stroke={1.5} />}
      />
      <ConsoleAction
        component={<HoldingsList accountData={cellData?.rowData?.original} />}
        title="Holdings"
        icon={<IconSteeringWheel stroke={1.5} />}
      />
    </div>
  );
};

export default AccountConsole;
