import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { env } from "../helpers/env.js";
import { RESET_AUTH_TOKEN } from "../redux/auth/token.slice.js";
import { RESET_AUTH_USER } from "../redux/auth/user.slice.js";
import { RESET_LOGGED_IN } from "../redux/auth/config.slice.js";

export const baseQuery = fetchBaseQuery({
  baseUrl: env.API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.app?.auth?.token;
    if (token?.access) {
      headers.set("Authorization", `Bearer ${token?.access}`);
    } else {
      headers.delete("Authorization");
    }
    return headers;
  },
});
let sessionExpiredToastShown = false;

export const baseQueryWithRefreshToken = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401 && !sessionExpiredToastShown) {
    sessionExpiredToastShown = true;
    toast.error("Your session has expired");
  }
  if (
    result?.error?.status === 503 ||
    result?.error?.status === 501 ||
    result?.error?.status === 502 ||
    result?.error?.status === 500
  ) {
    api.dispatch(RESET_AUTH_TOKEN());
    api.dispatch(RESET_LOGGED_IN());
    api.dispatch(RESET_AUTH_USER());
  }
  if (result?.error?.status === 401) {
    api.dispatch(RESET_AUTH_TOKEN());
    api.dispatch(RESET_LOGGED_IN());
    api.dispatch(RESET_AUTH_USER());
  }
  if (
    result?.error?.status === 503 ||
    result?.error?.status === 501 ||
    result?.error?.status === 502 ||
    result?.error?.status === 500
  ) {
    toast.error("Oops! Something went wrong.");
  }

  return result;
};
