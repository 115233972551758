import { Carousel } from "@mantine/carousel";
import { Box, Image, Text } from "@mantine/core";
import { brokerLists } from "../../utils/constant";

const BrokerSlider = () => {
  return (
    <div className="">
      <Carousel
        height={200}
        align="center"
        controlsOffset="md"
        controlSize={35}
        slideSize={{ base: "100%", sm: "50%", md: "20%" }}
        slideGap={{ base: 0, sm: "md" }}
        loop
      >
        {brokerLists.map((item, i) => {
          return (
            <Carousel.Slide key={i} p={10}>
              <Box
                bg={item.color}
                className="flex items-center justify-center h-full shadow-sm drop-shadow-sm"
              >
                <div className="text-center">
                  <div>
                    <Image w={100} h={100} src={item.image} alt={item.name} />
                  </div>
                  <Text fw="bold">{item.name}</Text>
                </div>
              </Box>
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </div>
  );
};

export default BrokerSlider;
