
import { IconAdjustments } from "@tabler/icons-react";
import ConsoleAction from "./ConsoleAction";
import PNLLists from "./pnlLists";

const ShowAllChildpnl = ({accountData}) => {
  return (
    <ConsoleAction
      component={<PNLLists accountData={accountData} />}
      title="All Child PnL"
      icon={<IconAdjustments stroke={1.5} />}
    />
  );
};

export default ShowAllChildpnl;
