import zerodha from "../assets/image/brokers/zerodha.png";
import aca from "../assets/image/brokers/aca.png";
import dhan from "../assets/image/brokers/dhan.png";
import alice from "../assets/image/brokers/alice.png";
import angel from "../assets/image/brokers/angel.png";
import flattrade from "../assets/image/brokers/flattrade.png";
import fyers from "../assets/image/brokers/fyers.png";
import shoonya from "../assets/image/brokers/shoonya.png";

const brokerLists = [
    {
      name: "Zerodha",
      type: "zerodha",
      image: zerodha,
      color: "red.0",
    },
    {
      name: "Aca",
      type: "alice-blue",
      image: aca,
      color: "blue.0",
    },
    {
      name: "Angel",
      type: "angelone",
      image: angel,
      color: "orange.0",
    },
    {
      name: "Dhan",
      type: "dhan",
      image: dhan,
      color: "green.0",
    },
    {
      name: "Alice",
      type: 'alice-blue',
      image: alice,
      color: "blue.0",
    },
  
    {
      name: "Flattrade",
      type: 'flattrade',
      image: flattrade,
      color: "indigo.0",
    },
    {
      name: "Fyers",
      type: "fyers",
      image: fyers,
      color: "pink.0",
    },
    {
      name: "Shoonya",
      type: 'shoonya',
      image: shoonya,
      color: "yellow.0",
    },
  ];

export {
    brokerLists
}