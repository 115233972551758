import BrokerLists from "../../components/dashboard/BrokersList.jsx";

const DashboardPage = () => {
  return (
    <>
      <BrokerLists />
    </>
  );
};
export default DashboardPage;
