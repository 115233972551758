import { env } from "../helpers/env";
import { useGetTableListsQuery } from "../services/table/table.services";

const APP_URL = env.API_URL;
  
  
  //custom react-query hook
  const useGetData = ({
	endpoint,
	globalFilter,
	sorting,
	pagination,
	columnFilterFns,
	columnFilters
}) => {
	const fetchURL = new URL(endpoint, APP_URL);
	fetchURL.searchParams.set('page', `${pagination.pageIndex}`);

	const sort = sorting.length
		? sorting[0]?.id + ':' + (sorting[0]?.desc ? 'desc' : 'asc')
		: '';
	fetchURL.searchParams.set('size', `${pagination.pageSize}`);
	fetchURL.searchParams.set('search', globalFilter ?? '');
	fetchURL.searchParams.set('sort', sort);
	fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
	fetchURL.searchParams.set(
		'filterModes',
		JSON.stringify(columnFilterFns ?? {})
	);

	return useGetTableListsQuery(fetchURL.href, {
		refetchOnReconnect: true,
		refetchOnMountOrArgChange: true
	});
};

export default useGetData;
  