import { Table, Text } from '@mantine/core';
import { useGetOrderBookListQuery } from '../../../services/account/broker.service';
import { useState } from 'react';

const OrderBookList = ({accountData}) => {

  const { data: orderList } = useGetOrderBookListQuery(accountData?.id, {skip: !accountData?.id});

  const [scrolled, setScrolled] = useState(false);

  const rows = orderList?.data?.map((order) => (
    <Table.Tr key={order?.orderid}>
      <Table.Td>{order?.orderid}</Table.Td>
      <Table.Td ta="center">{order?.transactiontype}</Table.Td>
      <Table.Td ta="center">{order?.status}</Table.Td>
      <Table.Td ta="center">{order?.tradingsymbol}</Table.Td>
      <Table.Td ta="center">{order?.producttype}</Table.Td>
      <Table.Td ta="center">{order?.quantity}</Table.Td>
      <Table.Td ta="center">{order?.filledshares}</Table.Td>
      <Table.Td ta="center">{order?.averageprice}</Table.Td>
      <Table.Td ta="center">{order?.updatetime}</Table.Td>
      <Table.Td ta="center">{order?.text}</Table.Td>
    </Table.Tr>
  ));

  return (
    <div>
      <div className="flex items-center justify-between gap-5 mb-4">
        <Text component="h3" fz={20} lineClamp={1} fw="bold">
          {accountData?.broker_id} - ORDERBOOK
        </Text>
      </div>
       <Table.ScrollContainer maw={1100} h={400} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Table
          withTableBorder
          withRowBorders
          withColumnBorders
          highlightOnHover
          striped
          verticalSpacing="xs"
          horizontalSpacing="xs"
          stickyHeader
          styles={{
            thead: {
              boxShadow: "var(--mantine-shadow-xs)",
              background: "var(--mantine-color-default)",
              zIndex: 9,
            },
            th: {
              fontSize: "var(--mantine-font-size-sm)",
            },
          }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ORDERID</Table.Th>
              <Table.Th ta="center">SIDE</Table.Th>
              <Table.Th ta="center">STATUS</Table.Th>
              <Table.Th ta="center">TRADING SYMBOL</Table.Th>
              <Table.Th ta="center">PRODUCT TYPE</Table.Th>
              <Table.Th ta="center">PLACED QUANTITY</Table.Th>
              <Table.Th ta="center">FILLED QUANTITY</Table.Th>
              <Table.Th ta="center">AVERAGE PRICE</Table.Th>
              <Table.Th ta="center">ORDER TIME</Table.Th>
              <Table.Th ta="center">REMARKS</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </div>
  )
}

export default OrderBookList
