import Heading from "../../components/heading/Heading.jsx";
import ContactInfo from "../../components/pages/contact/ContactInfo.jsx";
import ContactForm from "../../forms/entities/contact/ContactForm.jsx";
import { lazy, Suspense } from "react";
const ContactMapAddress = lazy(() =>
  import("../../components/pages/contact/ContactMapAddress.jsx"),
);

const ContactPage = () => {
  return (
    <section className="py-10">
      <div className="container">
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-center text-center max-w-[650px] mx-auto">
            <Heading
              description="Fill in the form below and we will get in touch with you shortly."
              title="Let's Talk"
            />
          </div>
        </div>
        <ContactInfo />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="w-full bg-white shadow-sm drop-shadow-sm p-5 md:p-10">
            <h3 className="mb-4">Let's Connect</h3>
            <ContactForm />
          </div>
          <div className="w-full bg-white shadow-sm drop-shadow-sm p-5 md:p-10">
            <Suspense fallback="Loading map...">
              <ContactMapAddress />
            </Suspense>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
