"use client";
import { Alert, createTheme } from "@mantine/core";
import alertCss from "./alert.module.css";

export const tableTheme = createTheme({
  components: {
    Alert: Alert.extend({
      classNames: {
        root: alertCss.root,
        body: alertCss.body,
        message: alertCss.message,
      },
    }),
  },
});
