import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mantine/core";
import InputText from "../../elements/InputText.jsx";
import {
  contactDefaultValue,
  ContactValidationSchema,
} from "./contact.validation.js";
import { useDoContactUsMutation } from "../../../services/auth/auth.services.js";
import TextAreaInput from "../../elements/TextAreaInput.jsx";

const ContactForm = () => {

  const [doContactUs, { isLoading }] = useDoContactUsMutation();

  const methods = useForm({
    resolver: yupResolver(ContactValidationSchema),
    mode: "onBlur",
    defaultValues: contactDefaultValue,
  });

  const handleContactForm = async (value) => {
    await doContactUs(value);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleContactForm)}>
        <div className="grid grid-cols-1 gap-3">
          <InputText
            name="name"
            label="Full name"
            props={{ withAsterisk: true }}
          />
          <InputText
            name="email"
            label="Email Address"
            props={{ type: "email", withAsterisk: true }}
          />
          <InputText
            name="phone"
            label="Mobile Number"
            props={{ type: "number", withAsterisk: true, maxLength: 10 }}
          />
          <InputText
            name="subject"
            label="Subject"
            props={{ withAsterisk: true }}
          />
          <TextAreaInput
            name="message"
            label="Message"
            isCharCount
            maxLength={800}
            props={{ withAsterisk: true }}
					/>
          <div className="mt-5">
            <Button
              size="xl"
              fullWidth
              type="submit"
              disabled={!methods.formState.isValid}
              loading={isLoading}
            >
              Send Message
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ContactForm;
