import PopupContext from '../../../popup/PoupContext';

const CancelButton = ({ cancel }) => {
	const { icon = 'gg:close', label = '', isPopup = true } = cancel;

	return (
		<>
			<div
				key={`act-cancel ${JSON.stringify(cancel.label)}`}
				className={`btn-act ${cancel.customClass}`}
			>
				{isPopup && (
					<PopupContext
						buttonLabel={label}
						buttonIcon={icon}
						modelTitle={cancel.popupTitle}
						component={cancel.component}
						customClass={`!p-1 w-0 !h-auto ${cancel.customClass}`}
						variant='subtle'
					/>
				)}
			</div>
		</>
	);
};

export default CancelButton;
