const ActionButtonWrapper = ({
	children
}) => {
	return (
		<div className="flex items-center justify-center gap-3 w-full">
			{children}
		</div>
	);
};
export default ActionButtonWrapper;
