import { Badge, Box, Image, Text } from "@mantine/core";
import { brokerLists } from "../../../utils/constant";
import AccountTrade from "../component/AccountTrade";

const getBrokerImage = (type) => {
  const imageData = brokerLists.find((item) => item.type === type);
  return imageData?.image || "";
};

const AccountListColumns = [
  {
    accessorKey: "broker_type",
    header: "Broker",
    size: 50,
    Cell: ({ cell }) => (
      <Box w={50}>
        <Image
          w={40}
          h={35}
          src={getBrokerImage(cell.getValue())}
          alt={cell.getValue()}
        />
      </Box>
    ),
  },
  {
    accessorKey: "accountDetail",
    header: "Name",
    id: "name",
    Cell: ({ cell }) => (
      <Text lineClamp={1} fw="bold">
        {cell.getValue()?.[0]?.client_name}
      </Text>
    ),
  },
  {
    accessorKey: "accountDetail",
    header: "Broker ID",
    id: "brokerId",
    Cell: ({ cell }) => (
      <Text fw="bold">{cell.getValue()?.[0]?.broker_id}</Text>
    ),
  },
  {
    accessorKey: "accountDetail",
    header: "Trade",
    size: 100,
    Cell: ({ cell, row }) => <AccountTrade cellData={cell} rowData={row} />,
  },
  {
    accessorKey: "accountDetail",
    header: "Opening Balance",
    id: "balance",
    Cell: ({ cell }) => <Text fw="bold">{cell.getValue()?.[0]?.balance}</Text>,
  },
  {
    accessorKey: "",
    header: "Connection",
    id: "connection",
    Cell: ({ cell }) => (
      <Badge size="md" color={cell.getValue() === true ? "red" : "green"}>
        Success
      </Badge>
    ),
  },
];

export default AccountListColumns;
