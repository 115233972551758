import InputPin from "../../../elements/InputPin.jsx";
import { Button, Text } from "@mantine/core";
import {
  OTPValidationSchema,
  verifyOTPInitial,
} from "../validation/auth.validation.js";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVerifyOTPMutation } from "../../../../services/auth/auth.services.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { dashboardRoutes } from "../../../../routes/dashboard.routes.jsx";
import { useContext } from "react";
import { RegisterContext } from "./RegisterProvider.jsx";
import ResendOTP from "../ResendOTP.jsx";

const VerifyOTPForm = () => {
  const navigate = useNavigate();
  const { setOtpVerified, user, canResendOtp } = useContext(RegisterContext);
  const [verifyOTP, { isLoading }] = useVerifyOTPMutation();
  // Handle Registration Form
  const methods = useForm({
    defaultValues: verifyOTPInitial,
    resolver: yupResolver(OTPValidationSchema),
    mode: "onBlur",
  });

  // Handle OTP Send
  const handleFormSubmit = async (value) => {
    console.log("value", value);
    await verifyOTP(value)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setOtpVerified(res?.success);
          setTimeout(() => {
            navigate(dashboardRoutes[0].path, { replace: true });
          }, 5000);
        }
      })
      .catch((error) => {
        setOtpVerified(false);
        toast.error(error?.data?.message);
      });
  };

  return (
    <>
    <div className="flex items-center flex-col justify-center pb-10">
      <Text size="xl" fw="bold">Verify OTP</Text>
    </div>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <div className="grid grid-cols-1 gap-3">
          <div className="flex items-center justify-center mb-4">
            <InputPin name="otp" label="Enter OTP" props={{ length: 6 }} />
          </div>
          <div className="flex items-center gap-2">
            <Button fullWidth type="submit" loading={isLoading}>
              Verify OTP
            </Button>
            <ResendOTP
              isSuccess={canResendOtp}
              mobile={user?.phone}
              userId={user?.user_id}
              props={{ fullWidth: true }}
            />
          </div>
        </div>
      </form>
    </FormProvider>
    </>
  );
};
export default VerifyOTPForm;
