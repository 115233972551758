import * as Yup from "yup";
import { emailRegex } from "../auth/validation/auth.validation.js";
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


export const ContactValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required.")
    .min(6, "Name must be at least 6 characters long.")
    .max(30, "User Name must not exceed 50 characters."),
  phone: Yup.string()
    .required("Mobile number is required.")
    .matches(phoneRegExp, "Invalid Mobile number.")
    .min(10, "Mobile number must be 10 digit number")
    .max(10, "Mobile number can not be greater than 10 digit"),
  email: Yup.string()
    .required("Email is required.")
    .matches(emailRegex, "Invalid email."),
  subject: Yup.string()
    .required("Subject is required.")
    .min(6, "Subject must be at least 6 characters long.")
    .max(60, "Subject must not exceed 60 characters."),
  message: Yup.string()
    .required("Message is required.")
    .min(6, "Message must be at least 10 characters long.")
    .max(800, "Message must not exceed 800 characters."),
});

export const contactDefaultValue = {
  name: "",
  phone: "",
  email: "",
  subject: "",
  message: "",
};
