import { BackgroundImage, Flex, Image, Paper, Text } from "@mantine/core";
import MultiColorDots from "../../components/heading/MultiColorDots";
import aboutImg from "../../assets/image/others/1920x433_2.jpg";
import aboutValueImg from "../../assets/image/others/res.jpg";
import img1 from "../../assets/image/icon/8.png";
import img2 from "../../assets/image/icon/9.png";
import img3 from "../../assets/image/icon/10.png";
import img4 from "../../assets/image/icon/11.png";
import AboutUsMission from "./AboutUsMission";

const aboutKeyPoints = [
  {
    title: "ALGO / Automated Trading",
    info: "We have ALGO with high win ratio & risk : reward ratio for all segment. Like Nifty, Banknifty, Stocks, Option Buying ,Option Selling etc.",
    icon: img1,
  },
  {
    title: "Nifty 100 Stocks Selection",
    info: "We have developed scanner and Indicators for momentum stocks for swing, option Buying & option selling trading with high win ratio.",
    icon: img2,
  },
  {
    title: "Scalping Course",
    info: "We have developed Indicators for scalping trading with high accuracy with consistently profitable trade. Also we have provided indicators for Scalping set up & Intraday trading.",
    icon: img3,
  },
  {
    title: "Fundamental + Technical Analysis",
    info: "We have developed Indicators & Strategy to find Nifty 100 stocks based on Fundamental + Technical Analysis.",
    icon: img4,
  },
];

const aboutValue = {
  title: "Our value",
  data: [
    {
      title: "Innovation",
      info: "We embrace creativity and cultivate a culture of innovation to develop solutions that meet the evolving needs of our customers and the communities we serve",
    },
    {
      title: "Integrity",
      info: "We uphold the highest standards of honesty, transparency, and ethical behavior. Our commitment to integrity is unwavering in all our interactions, both internally and externally",
    },
    {
      title: "Customer-Centricity",
      info: "Our customers are at the heart of everything we do. We strive to understand their challenges, exceed their expectations, and provide them with valuable, user- friendly solutions",
    },
    {
      title: "Quality Excellence",
      info: "We are committed to delivering products and services of the highest quality. Our pursuit of excellence ensures that our solutions add real value to the lives of our customers",
    },
  ],
};

const AboutUsPage = () => {
  return (
    <div>
      <BackgroundImage src={aboutImg}>
        <Flex justify='center' align='center' w='100%' mih={200}>
          <div className=''>
            <MultiColorDots />
            <Text fz={30} ta='center' c='white' fw='bold'>
              About Us
            </Text>
          </div>
        </Flex>
      </BackgroundImage>
      <section className='py-10'>
        <div className='container'>
          <div className='grid grid-cols-1'>
            <div className='flex justify-center items-center'>
              <div className='text-center'>
                <MultiColorDots />
                <h2 className='text-4xl leading-[1.4] mb-5'>
                  We Provide the{" "}
                  <span className='text-[var(--mantine-primary-color-filled)]'>
                    Best Trading
                  </span>{" "}
                  Services & Platform
                </h2>
                <Text mx='auto' maw='1050px'>
                  You are interested in trading but know nothing about it and
                  worry how to do it well for every new comers it is happen they
                  want to come in Trading but scared to start that's why on
                  Occtans Trading Service ,we guide our customer so they can
                  learn and earn both at same time. you can run over your free
                  trials in order to get an idea about our ALGO Strategies.
                </Text>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mt-10'>
              {aboutKeyPoints.map((item) => {
                return (
                  <Paper key={item.title} p='lg' shadow='xs'>
                    <Flex justify='center' align='center' mb={20}>
                      <Image maw={60} mx='auto' src={item.icon} />
                    </Flex>
                    <Text fw='bold' fz={18} mb={15} ta='center'>
                      {item.title}
                    </Text>
                    <Text fz={14} ta='center'>
                      {item.info}
                    </Text>
                  </Paper>
                );
              })}
            </div>
          </div>
          <AboutUsMission />
        </div>
      </section>
      <BackgroundImage src={aboutValueImg}>
        <Flex
          justify='center'
          align='center'
          w='100%'
          mih={200}
          className='backdrop-blur-sm'
        >
          <div className='container py-10'>
            <div className='grid grid-rows-1 gap-5'>
              <Text fz={30} ta='center' fw='bold'>
                {aboutValue.title}
              </Text>

              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
                {aboutValue.data.map((item, o) => {
                  return (
                    <Paper key={o} p='lg' shadow='xs'>
                      <Text fz={20} fw='bold' mb={15}>
                        {item.title}
                      </Text>
                      <Text fz={14}>{item.info}</Text>
                    </Paper>
                  );
                })}
              </div>
            </div>
          </div>
        </Flex>
      </BackgroundImage>
    </div>
  );
};

export default AboutUsPage;
