
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithRefreshToken } from '../api.service';

export const tableApi = createApi({
	reducerPath: 'table',
	baseQuery: baseQueryWithRefreshToken,
	tagTypes: ['GET_TABLE_DATA'],
	endpoints: builder => ({
		getTableLists: builder.query({
			query: url => url,
			providesTags: (result) => {
				if (Array.isArray(result?.data?.data)) {
					console.log(result,'result');
					
					return result
						? [
								...result.data.data.map(({ createdDate }) => ({
									type: 'GET_TABLE_DATA',
									createdDate
								})),
								{ type: 'GET_TABLE_DATA', createdDate: 'LIST' }
						]
						: [{ type: 'GET_TABLE_DATA', createdDate: 'LIST' }];
				} else {
					return [{ type: 'GET_TABLE_DATA', createdDate: 'LIST' }];
				}
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			}
		})
	})
});

export const { useGetTableListsQuery } = tableApi;
