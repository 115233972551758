import { Box, Image, Tooltip } from "@mantine/core";
import { brokerLists } from "../../../utils/constant";
import { IconSquareRoundedCheckFilled } from "@tabler/icons-react";
import AccountQuantity from "../component/AccountQuantity";
import ChildAccountTrade from "../component/ChildAccountTrade";
import AccountConsole from "../component/AccountConsole";
import ExitPositions from "../component/ExitPositions";
import ChildLastOrderDetails from "../component/ChildLastOrderDetails";

const getBrokerImage = (type) => {
  const imageData = brokerLists.find((item) => item.type === type);
  return imageData?.image || "";
};

const ChildAccountListColumns = [
  {
    accessorKey: "accounts",
    header: "",
    id: "logo",
    size: 80,
    Cell: ({ cell }) => {
      return (
        <div>
          <Tooltip
            label={cell.getValue()?.broker_type}
            color="blue"
            arrowSize={12}
            withArrow
            transitionProps={{ transition: "pop", duration: 200 }}
          >
            <Image
              w={50}
              h={50}
              src={getBrokerImage(cell.getValue()?.broker_type)}
              alt={cell.getValue()?.broker_type}
            />
          </Tooltip>
        </div>
      );
    },
  },
  {
    accessorKey: "accountsDetails",
    header: "Child Details",
    id: "accountsDetails.client_name",
    Cell: ({ cell }) => (
      <Box w={100} className="text-center">
        <p className="font-bold">{cell?.getValue()?.broker_id || "H123456"}</p>
        <p>{cell?.getValue()?.client_name || "Default Child"}</p>
      </Box>
    ),
  },
  {
    accessorKey: "id",
    header: "Login Status",
    id: "status",
    size: 20,
    Cell: () => (
      <>
        <div className="flex items-center justify-center text-green-500">
          <IconSquareRoundedCheckFilled size={30} />
        </div>
        {/*<div className="flex items-center justify-center text-gray-300">*/}
        {/*  <IconSquareRoundedXFilled size={30} />*/}
        {/*</div>*/}
      </>
    ),
  },
  {
    accessorKey: "accountsDetails",
    header: "Live Balance",
    id: "accountsDetails.balance",
    Cell: ({ cell }) => (
      <Box w={100} className="text-center">
        <p>{cell?.getValue()?.balance}</p>
      </Box>
    ),
  },
  {
    accessorKey: "accountsDetails",
    header: "Quantity Multiplier",
    id: "accountsDetails.quantity",
    Cell: ({ cell, row }) => (
      <div className="flex items-center justify-center">
        <AccountQuantity cellData={cell} rowData={row} />
      </div>
    ),
  },
  {
    accessorKey: "accountsDetails",
    header: "Trade On/Off",
    id: "accountsDetails.trade",
    Cell: ({ cell, row }) => (
      <ChildAccountTrade cellData={cell} rowData={row} />
    ),
  },
  {
    accessorKey: "accountsDetails",
    header: "Console",
    id: "console",
    size: 100,
    Cell: ({ cell, row }) => <AccountConsole cellData={cell} rowData={row} />,
  },
  // {
  //   accessorKey: "accountsDetails",
  //   header: "Exit Child",
  //   id: "exit",
  //   Cell: ({ cell, row }) => <ExitPositions cellData={cell} rowData={row} />,
  // },
  {
    accessorKey: "accountsDetails",
    header: "Last Order Details",
    id: "order",
    Cell: ({ cell, row }) => (
      <ChildLastOrderDetails cellData={cell} rowData={row} />
    ),
  },
  {
    accessorKey: "pnl",
    header: "Live PnL",
    id: "pnl",
  },
];

export default ChildAccountListColumns;
