const NoticeToSelectBrokerAccount = () => {
  return (
    <div className="w-full text-center">
      <h6 className="mt-3">
        Please select the <b className="text-green-500">BROKER ACCOUNT</b> from
        above selection
      </h6>
    </div>
  );
};

export default NoticeToSelectBrokerAccount;
