import { Image, List, ListItem, Paper, Text } from "@mantine/core";
import aboutImg from "../../assets/image/others/960x872_home.jpg";

const aboutKeys = [
  {
    title: "Our Mission",
    info: "To educate individuals about financial literacy, investment strategies, and risk management. Empowering people with the knowledge and skills to make informed financial decisions and navigate the complexities of the stock market. To provide clients with accurate and powerful analysis of financial markets",
    list: [
      "To grow wealth over the long term",
      "To provide a best platform for copy & ALGO Trade",
      "To develop and provide high win ratio Strategies & Indicators",
    ],
    extra: [],
  },
  {
    title: "Our Vision",
    info: "Occbot trading is an initiative for the people interested in earning more, willing to step forward and want to become freebee from financial situations. We provide knowledge, self learning experience and help to become pro trader.",
    list: [
      "Commitment to continuous improvement and evolution",
      "We guide our customer so they can learn and earn both at same time",
      "Building and maintaining Clients confidence",
    ],
    extra: [],
  },
];

const AboutUsMission = () => {
  return (
    <div className='mt-10 grid grid-cols-2 gap-5'>
      <div className='grid grid-cols-1 gap-5'>
        {aboutKeys?.map((item) => {
          return (
            <Paper shadow='xs' p='md' key={item.title}>
              <Text fz={25} fw='bold'>
                {item.title}
              </Text>
              <Text fz={14}>{item.info}</Text>
              <List mt='md' fz={15} listStyleType='disc'>
                {item.list.map((li, i) => {
                  return <ListItem key={i}>{li}</ListItem>;
                })}
              </List>
            </Paper>
          );
        })}
      </div>
      <div>
        <Image radius='sm' alt='About Us' fit='contain' src={aboutImg} />
      </div>
    </div>
  );
};

export default AboutUsMission;
