import { ActionIcon, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useDeleteBrokerAccountMutation } from "../../../services/account/broker.service";
import { Icon } from "@iconify/react";
import { reFetchTableData } from "../../../utils/functions";

const DeleteBrokerAccountModal = ({ brokerData }) => {
  const [deleteBrokerAccount, { isLoading: deleteLoading }] =
    useDeleteBrokerAccountMutation();

  const onDelete = async () => {
    await deleteBrokerAccount(brokerData?.id);
    closeAllModals();
    reFetchTableData();
  };
  return (
    <div className="p-5">
      <div className="flex items-center justify-center mb-5">
        <ActionIcon c="red" bg="red.1" variant="light" size={80} radius="100%">
          <Icon width={50} icon="pepicons-print:question" />
        </ActionIcon>
      </div>
      <div className="mt-5 text-center">
        <h2 className="mb-3">Are you sure ?</h2>
        <h3>
          Do you really want to delete{" "}
          <span className="text-red-500">{brokerData?.client_id}</span> ?
        </h3>
        <div className="flex items-center justify-center mt-10 gap-4 max-w-[300px] mx-auto">
          <Button
            fullWidth
            text="Delete"
            type="submit"
            onClick={onDelete}
            loading={deleteLoading}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="outline"
            type="button"
            onClick={closeAllModals}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBrokerAccountModal;
