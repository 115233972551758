import React from "react";
import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { downloadExcelFile } from "../../../services/account/download.service";

const DownloadChildOrderBook = ({accountData}) => {

  const downloadOrderList = async () => {
    await downloadExcelFile(`broker/download-child-orderbook/${accountData?.id}`, 'GET', 'orders.xlsx');
  }

  return (
    <Button size="sm" variant="subtle" leftSection={<IconDownload />} onClick={downloadOrderList}>
      Child Orderbooks
    </Button>
  );
};

export default DownloadChildOrderBook;
