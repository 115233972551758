import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { baseQueryWithRefreshToken } from "../api.service.js";

export const accountApi = createApi({
  reducerPath: "accounts",
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: ["GET_MASTER_ACCOUNT_LIST", "GET_CHILD_ACCOUNT_LIST", "GET_ACCOUNT_LISTS", "GET_ALL_ACCOUNT_LISTS", "GET_USER_PROFILE"],
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query() {
        return {
          url: `/user/get-profile`,
          method: "GET",
          providesTags: ["GET_USER_PROFILE"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response?.data;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getMasterAccountLists: builder.query({
      query() {
        return {
          url: `/broker/get-master-accounts`,
          method: "GET",
          providesTags: ["GET_MASTER_ACCOUNT_LIST"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response?.data;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getChildAccountLists: builder.query({
      query() {
        return {
          url: `/broker/get-child-accounts`,
          method: "GET",
          providesTags: ["GET_CHILD_ACCOUNT_LIST"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response?.data;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getAccountLists: builder.query({
      query() {
        return {
          url: `/broker/get-broker-account-list`,
          method: "GET",
          providesTags: ["GET_ACCOUNT_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          const dropData = response?.data
            ?.filter((item) => item?.is_active)
            .map((item) => {
              return {
                label: item.client_id,
                value: `${item.client_id}`,
              };
            });
          return {
            server: response,
            dropdown: dropData,
          };
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    addMasterAccount: builder.mutation({
      query(data) {
        return {
          url: `/broker/trade-account`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_MASTER_ACCOUNT_LIST"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    updateMasterAccount: builder.mutation({
      query(data) {
        return {
          url: `/broker/trade-account`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GET_MASTER_ACCOUNT_LIST"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    deleteMasterAccount: builder.mutation({
			query: id => ({
				url: `/broker/trade-account/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: [{ type: 'GET_MASTER_ACCOUNT_LIST' }],
			transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
		}),
    getAllAccountLists: builder.query({
      query() {
        return {
          url: `/broker/get-broker-all-account-list`,
          method: "GET",
          providesTags: ["GET_ALL_ACCOUNT_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          const dropData = response?.data
            ?.filter((item) => item?.is_active)
            .map((item) => {
              return {
                label: item.client_id,
                value: `${item.client_id}`,
              };
            });
          return {
            server: response,
            dropdown: dropData,
          };
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    changeCopyTrade: builder.mutation({
      query(data) {
        return {
          url: `/broker/change-copy-trade/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_MASTER_ACCOUNT_LIST"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    ChangeAccountQuantity: builder.mutation({
      query(data) {
        return {
          url: `/broker/change-quantity/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_MASTER_ACCOUNT_LIST"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    ChangeAccountTrade: builder.mutation({
      query(data) {
        return {
          url: `/broker/change-trade/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_MASTER_ACCOUNT_LIST"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    getAllMasterAccountLists: builder.query({
      query() {
        return {
          url: `/broker/get-master-accounts`,
          method: "GET",
          providesTags: ["GET_MASTER_ACCOUNT_LIST"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          const dropData = response?.data
            ?.filter((item) => item?.is_active)
            .map((item) => {
              return {
                label: item.broker_id,
                value: `${item.id}`,
              };
            });
          return {
            server: response,
            dropdown: dropData,
          };
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
  }),
});

export const {
  useGetMasterAccountListsQuery,
  useGetChildAccountListsQuery,
  useGetAccountListsQuery,
  useAddMasterAccountMutation,
  useDeleteMasterAccountMutation,
  useGetAllAccountListsQuery,
  useChangeCopyTradeMutation,
  useChangeAccountQuantityMutation,
  useChangeAccountTradeMutation,
  useUpdateMasterAccountMutation,
  useGetUserProfileQuery,
  useGetAllMasterAccountListsQuery
} = accountApi;
