import { combineReducers } from "@reduxjs/toolkit";
import { tokenSlice } from "./token.slice.js";
import { userSlice } from "./user.slice.js";
import { authConfigSlice } from "./config.slice.js";

export const authReducer = combineReducers({
  config: authConfigSlice.reducer,
  token: tokenSlice.reducer,
  user: userSlice.reducer,
});
