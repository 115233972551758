import * as Yup from "yup";

export const ChildAccountSchema = Yup.object().shape({
  clientId: Yup.string().required("Client ID is required"),
  accountType: Yup.string("child"),
  trade: Yup.string().optional(),
  quantity: Yup.string().required("Multiplier is required"),
  quantityType: Yup.string().optional(),
  parent_id: Yup.string().required("Master account is required"),
});

export const ChildAccountDefaultValue = Yup.reach(ChildAccountSchema).key;
