import { Link } from "react-router-dom";
import { authRouteLinks } from "../../routes/auth.routes.jsx";
import ForgotPasswordForm from "../../forms/entities/auth/ForgotPasswordForm.jsx";

const ForgotPasswordPage = () => {
  return (
    <div>
      <ForgotPasswordForm />
      <div className="mt-5 text-center flex flex-col flex-wrap items-center justify-center gap-3">
        <Link
          className="w-full block hover:text-blue-700"
          to={authRouteLinks.login.path}
        >
          Back to Login
        </Link>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
