import React from "react";
import { Affix, Button, rem, Transition } from "@mantine/core";
import { IconArrowUp } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";

const GoToTop = () => {
  const [scroll, scrollTo] = useWindowScroll();
  return (
    <Affix position={{ bottom: 20, right: 10 }}>
      <Transition transition="slide-up" mounted={scroll.y > 900}>
        {(transitionStyles) => (
          <Button
            variant="subtle"
            leftSection={
              <IconArrowUp style={{ width: rem(16), height: rem(16) }} />
            }
            style={transitionStyles}
            onClick={() => scrollTo({ y: 0 })}
          >
            Top
          </Button>
        )}
      </Transition>
    </Affix>
  );
};

export default GoToTop;
