import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
};

export const authConfigSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_LOGGED_IN: (state, action) => ({
      ...state,
      isLoggedIn: action.payload,
    }),
    RESET_LOGGED_IN: () => initialState,
  },
});

export const { SET_LOGGED_IN, RESET_LOGGED_IN } = authConfigSlice.actions;

export const GET_AUTH_CONFIG = (state) => state.app.auth.config;
