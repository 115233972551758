import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { appReducer } from "./app.reducer.js";
import { authApi, logoutApi } from "../services/auth/auth.services.js";
import { brokerApi } from "../services/account/broker.service.js";
import { accountApi } from "../services/account/accounts.service.js";
import { tableApi } from "../services/table/table.services.js";
import { paymentApi } from "../services/account/payment.service.js";

const persistConfig = {
  key: "dhpro",
  keyPrefix: "quant:",
  version: "v1",
  storage: storage,
  stateReconciler: hardSet(),
  whitelist: ["auth"],
  debug: import.meta.env.MODE === "development",
  transforms: [
    encryptTransform({
      secretKey: "EW?wes_w23kei2WE3Dxxdv@lsd_ewr/23f@_%^ad32dc_w2#d@A",
      onError: function (error) {
        console.log("Critical error occurred ==>", error);
      },
    }),
  ],
};

export const store = configureStore({
  devTools: import.meta.env.MODE === "development",
  reducer: {
    app: persistReducer(persistConfig, appReducer),
    [authApi.reducerPath]: authApi.reducer,
    [brokerApi.reducerPath]: brokerApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [tableApi.reducerPath]: tableApi.reducer,
    [logoutApi.reducerPath]: logoutApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer
  },
  middleware: (gdm) =>
    gdm({ serializableCheck: false }).concat(
      authApi.middleware,
      brokerApi.middleware,
      accountApi.middleware,
      tableApi.middleware,
      logoutApi.middleware,
      paymentApi.middleware
    ),
});

setupListeners(store.dispatch);
export const persist = persistStore(store);
