import { Button, Menu, Text } from "@mantine/core";
import {
  IconGridDots,
  IconLayoutDashboard,
  IconLayoutGrid,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { dashboardRoutes } from "../../routes/dashboard.routes.jsx";

const SwitchDashboard = () => {
  return (
    <Menu
      withArrow
      arrowSize={10}
      transitionProps={{ transition: "pop", duration: 200 }}
    >
      <Menu.Target>
        <Button
        size="sm"
          color="pink"
          variant="light"
          leftSection={<IconLayoutDashboard />}
        >
          Copy Trade
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          component={Link}
          to={dashboardRoutes[1].path}
          leftSection={<IconGridDots />}
        >
          <Text fw="bold">Account</Text>
        </Menu.Item>
        <Menu.Item
          component={Link}
          to={dashboardRoutes[2].path}
          leftSection={<IconLayoutGrid />}
        >
          <Text fw="bold">Trading</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SwitchDashboard;
