import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { env } from "../../helpers/env";
import { store } from "../../redux/store.js";
import { SET_AUTH_USER } from "../../redux/auth/user.slice.js";
import { RESET_LOGGED_IN, SET_LOGGED_IN } from "../../redux/auth/config.slice.js";
import { RESET_AUTH_TOKEN, SET_AUTH_TOKEN } from "../../redux/auth/token.slice.js";
import { baseQueryWithRefreshToken } from "../api.service.js";

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: env.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query(data) {
        return {
          url: `/auth/register`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    login: builder.mutation({
      query(data) {
        return {
          url: `/auth/login`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          console.log({ response });
          store.dispatch(SET_LOGGED_IN(!!response?.data?.accessToken));
          store.dispatch(SET_AUTH_TOKEN(response));
          store.dispatch(SET_AUTH_USER(response));
          return response;
        }
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message);
        }
      },
    }),
    forgotPassword: builder.mutation({
      query(data) {
        return {
          url: `/auth/forgot-password`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          console.log("response", response);
          return response;
        }
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message);
        }
      },
    }),
    resetPassword: builder.mutation({
      query({ token, password }) {
        return {
          url: `/auth/reset-password`,
          method: "POST",
          body: { token: token, password: password },
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          console.log("response", response);
          return response;
        }
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message);
        }
      },
    }),
    sendOTP: builder.mutation({
      query({ mobileNumber, userId, reSend = false }) {
        return {
          url: `/auth/send-otp`,
          method: "POST",
          body: {
            mobileNumber: mobileNumber,
            userId: userId,
            reSend: reSend
          },
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.data?.message);
          return response?.data;
        }
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message);
          return error;
        }
      },
    }),
    verifyOTP: builder.mutation({
      query(data) {
        return {
          url: `/auth/verify-otp`,
          method: "POST",
          body: { otp: data?.otp },
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message || 'Something went wrong. Please try again after sometimes');
          return error;
        }
      },
    }),
    doContactUs: builder.mutation({
      query(data) {
        return {
          url: `/auth/contact-us`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.message || 'Something went wrong. Please try again after sometimes');
          return error;
        }
      },
    }),
  }),
});

export const logoutApi = createApi({
	reducerPath: 'authLogout',
	baseQuery: baseQueryWithRefreshToken,
	tagTypes: ['AUTH_DESTROY'],
	endpoints: builder => ({
		logout: builder.mutation({
			query() {
				return {
					url: `/auth/logout`,
					method: 'POST'
				};
			},
			invalidatesTags: [{ type: 'AUTH_DESTROY' }],
			transformResponse: (response, meta) => {
				if (meta.response.ok) {
          console.log('store.dispatch(RESET_AUTH_TOKEN());')
          store.dispatch(RESET_LOGGED_IN());
          store.dispatch(RESET_AUTH_TOKEN());
				}
			}
		})
	})
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useSendOTPMutation,
  useVerifyOTPMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useDoContactUsMutation
} = authApi;

export const { useLogoutMutation } = logoutApi;