import { publicRoutes } from "../../routes/public.routes.jsx";
import { NavLink } from "react-router-dom";

const HeaderLinks = () => {
  return (
    <div className="flex items-center gap-1 header-menu">
      {publicRoutes
        .filter((item) => item.path !== "*")
        .map((route) => {
          return (
            <NavLink
              key={route.name}
              className="px-3 py-2 font-bold text-md capitalize"
              to={route.path}
            >
              <div className="flex items-center gap-1">
                <span className="inline-flex items-center w-[18px] h-[18px] justify-center">
                  {route.icon}
                </span>
                <span className="inline-flex items-center text-[14px] justify-center">
                  {route.name}
                </span>
              </div>
            </NavLink>
          );
        })}
    </div>
  );
};

export default HeaderLinks;
