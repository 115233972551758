export const tableConfigCustom = {
  manualFiltering: true,
  manualPagination: true,
  manualSorting: true,
  enableFilters: true,
  enableFullScreenToggle: true,
  enableColumnFilters: true,
  enableColumnFilterModes: false,
  columnFilterModeOptions: [
    "contains",
    "startsWith",
    "endsWith",
    "equals",
    "notEquals",
    "fuzzy",
  ],
  enableDensityToggle: true,
  enableColumnActions: false,
  enableGlobalFilterRankedResults: true,
  enableGlobalFilterModes: false,
  enableGlobalFilter: true,
  enableHiding: true,
  enableColumnResizing: false,
  columnResizeMode: "onChange",
  columnFilterDisplayMode: "subheader",
  enableColumnOrdering: false,
  enableGrouping: true,
  enablePinning: false,
  enableSortingRemoval: true,
  enableTopToolbar: true,
  enableBottomToolbar: true,
  enableSorting: true,
  memoMode: "cells",
  enableStickyHeader: false,
  enableStickyFooter: false,
  enableRowActions: true,
  enablePagination: true,
  positionActionsColumn: "last",
  paginationDisplayMode: "pages",
  initialState: {
    showColumnFilters: false,
    showGlobalFilter: true,
    density: "xs",
    columnPinning: {
      right: ["mrt-row-actions"],
    },
  },
  mantineTableProps: {
    highlightOnHover: false,
    withColumnBorders: true,
    withRowBorders: true,
    withTableBorder: true,
    striped: false,
    horizontalSpacing: "md",
  },
  mantinePaginationProps: {
    withEdges: true,
    size: "lg",
    rowsPerPageOptions: ["10", "20", "50", "75"],
  },
  mantineBottomToolbarProps: {
    style: {
      border: "0px solid var(--colors-gray-300)",
      minHeight: "61px",
    },
  },
  mantineColumnActionsButtonProps: {
    size: "md",
  },
  mantineTableHeadProps: {
    className: "w-full !shadow-sm !drop-shadow-sm",
  },
  mantineTableHeadCellProps: {
    px: 16,
  },
  mantineTopToolbarProps: {
    className:
      "shadow-sm drop-shadow-sm [&>.inexture-Flex-root]:items-center [&_.inexture-Alert-message]:mt-0",
  },
  mantineSearchTextInputProps: {
    placeholder: "Search",
    style: { minWidth: "200px" },
    variant: "default",
  },
  displayColumnDefOptions: {
    "mrt-row-actions": {
      size: 100,
      mantineTableHeadCellProps: {
        align: "center",
        w: "100%",
        className: "flex items-center justify-center gap-5",
      },
    },
    "mrt-row-numbers": {
      enableColumnOrdering: false,
      enableResizing: false,
    },
  },
  enableRowNumbers: false,
  positionGlobalFilter: "left",
};
