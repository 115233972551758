import { Navigate, Outlet } from "react-router-dom";
import Logo from "../components/common/Logo.jsx";
import { useSelector } from "react-redux";
import { GET_AUTH_CONFIG } from "../redux/auth/config.slice.js";
import { dashboardRoutes } from "../routes/dashboard.routes.jsx";
import RegisterProvider from "../forms/entities/auth/register/RegisterProvider.jsx";

const AuthLayout = () => {
  const { isLoggedIn } = useSelector(GET_AUTH_CONFIG);

  if (isLoggedIn) {
    return <Navigate to={dashboardRoutes[1].path} />;
  }

  return (
    <RegisterProvider>
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="container">
          <div className="flex items-center justify-center pb-10">
            <Logo />
          </div>
          <div className="grid grid-cols-1">
            <div className="shadow-sm drop-shadow-sm bg-white rounded w-full max-w-[550px] mx-auto">
              <div className="pt-6 pb-8 px-10">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RegisterProvider>
  );
};
export default AuthLayout;
