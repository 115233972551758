import Welcome from "../../components/pages/home/Welcome.jsx";
import HomeServices from "../../components/pages/home/HomeServices.jsx";
import HomeBrokers from "../../components/pages/home/HomeBrokers.jsx";
import IntroAbout from "../../components/pages/home/IntroAbout.jsx";

const HomePage = () => {
  return (
    <section>
      <Welcome />
      <HomeBrokers />
      <HomeServices />
      <IntroAbout />
    </section>
  );
};
export default HomePage;
