import { env } from "../helpers/env";

const redirectToFyersAuthLink = async (vData) => {
    const authEndpoint = 'https://api-t1.fyers.in/api/v3/generate-authcode';
    
    const authorizationUrl = `${authEndpoint}?client_id=${vData?.api_key}&redirect_uri=${env.FYERS_CALLBACK_URL}&response_type=code&state=sample_state&type=fyers`;

    window.open(authorizationUrl);
};

export {
    redirectToFyersAuthLink
}