"use client";
import { useMemo, useRef, useState } from "react";
import "mantine-react-table/styles.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Box, MantineProvider, ScrollArea, Text } from "@mantine/core";
import { tableConfigCustom } from "./utils/table.config";
import { tableTheme } from "./theme/tableTheme";
import useGetData from "../../hooks/useGetData";
import TableToolbarInternalActions from "./components/TableToolbarInternalActions";
import TableNoData from "./general/action/TableNoData";
import TableHeading from "./components/TableHeading.jsx";

const GlobalTable = ({
  columns = [],
  endpoint = "",
  sortBy = "id",
  tableProps,
  rightAction = null,
  heading,
}) => {
  const tableContainerRef = useRef(null);
  const columnsKey = useMemo(() => columns, []);

  const [sorting, setSorting] = useState([{ desc: true, id: sortBy }] || []);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState(
    Object.fromEntries(
      columns.map(({ accessorKey }) => [accessorKey, "contains"]),
    ),
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const {
    data: tableData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetData({
    endpoint,
    globalFilter,
    sorting,
    pagination,
    columnFilterFns,
    columnFilters,
  });

  console.log("tableData", tableData);

  const totalRowCount = tableData?.total ?? 0;

  const tableOptions = {
    columns: columnsKey,
    data: tableData?.data ?? [],
    rowCount: totalRowCount,
    mantineLoadingOverlayProps: {
      visible: isLoading || isFetching,
      loaderProps: {
        type: "dots",
      },
    },
    state: {
      columnFilterFns,
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
      showAlertBanner: isError,
      showProgressBars: false,
      showLoadingOverlay: isFetching,
      showSkeletons: isFetching,
    },
    onPaginationChange: setPagination,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    mantineTableContainerProps: () => ({
      ref: tableContainerRef,
      component: ScrollArea,
      // style: {
      //   height: table.getState().isFullScreen
      //     ? "calc(100vh - var(--app-shell-header-height) - 10px)"
      //     : "calc(100vh - var(--app-shell-header-height) - 300px)",
      // },
    }),
    renderTopToolbarCustomActions: () => <TableHeading heading={heading} />,
    renderToolbarInternalActions: ({ table }) => {
      return (
        <TableToolbarInternalActions
          rightAction={rightAction}
          table={table}
          refetch={refetch}
          id={"table-fetch"}
        />
      );
    },
    renderEmptyRowsFallback: ({ table }) => {
      return (
        <Box
          sx={{
            height: table.getState().isFullScreen
              ? "calc(100vh - var(--mantine-header-height) - var(--mantine-header-height) - 80px)"
              : "calc(100vh - var(--mantine-header-height) - var(--mantine-header-height) - 243px)",
          }}
        >
          <TableNoData />
        </Box>
      );
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: "red",
          children: (
            <Text
              fw="bold"
              fz="md"
              c="red"
              className="flex items-center justify-center w-full"
            >
              Error loading data from server
            </Text>
          ),
          className:
            "[&_.inexture-Alert-message]:mt-0 [&_.inexture-Alert-message>.mantine-Flex-root]:bg-red-500",
        }
      : {
          className: "[&_.mantine-Flex-root]:px-0",
        },
    mantinePaperProps: ({ table }) => ({
      shadow: "xs",
      withBorder: false,
      style: {
        zIndex: table.getState().isFullScreen ? 102 : 100,
      },
    }),
    ...tableConfigCustom,
    ...tableProps,
  };

  const table = useMantineReactTable(tableOptions);

  return (
    <MantineProvider theme={tableTheme}>
      <MantineReactTable table={table} />
    </MantineProvider>
  );
};

export default GlobalTable;
