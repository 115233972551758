import Logo from "./Logo.jsx";
import { Burger, Button, Group } from "@mantine/core";
import { Link } from "react-router-dom";
import { authRouteLinks } from "../../routes/auth.routes.jsx";
import { useSelector } from "react-redux";
import { GET_AUTH_CONFIG } from "../../redux/auth/config.slice.js";
import UserMenu from "../user/UserMenu.jsx";
import SwitchDashboard from "./SwitchDashboard.jsx";
import HeaderLinks from "./HeaderLinks.jsx";

const Header = ({ toggle, opened }) => {
  const { isLoggedIn } = useSelector(GET_AUTH_CONFIG);
  return (
    <Group h="100%" px="md">
      <Group justify="space-between" style={{ flex: 1 }}>
        <Logo />
        <Group ml="xl" gap={20} visibleFrom="md">
          <HeaderLinks />
          {!isLoggedIn ? (
            <div className="flex items-center justify-end gap-3">
              <Button size="sm" component={Link} to={authRouteLinks.login.path}>
                {authRouteLinks.login.name}
              </Button>
              <Button
              size="sm"
                variant="outline"
                component={Link}
                to={authRouteLinks.register.path}
              >
                {authRouteLinks.register.name}
              </Button>
            </div>
          ) : (
            <div className="flex items-center justify-end gap-5">
              <SwitchDashboard />
              <UserMenu />
            </div>
          )}
        </Group>
        <Group hiddenFrom="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" />
        </Group>
      </Group>
    </Group>
  );
};
export default Header;
