import { toast } from "react-toastify";
import { env } from "../../helpers/env";
import { store } from "./../../redux/store";

const baseUrl = env.API_URL;

const downloadExcelFile = async (endpoint, method, name) => {
    try {
        const entireState = store.getState();
        const tokenData = entireState?.app?.auth?.token;
        const response = await fetch(`${baseUrl}/${endpoint}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenData?.access}`
            },
        });

        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = name;

        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
    } catch (err) {
        console.log("Error downloading data", err);
        toast.error('Something wen wrong. Please try again after sometime.')
    }
}

export {
    downloadExcelFile
}