import React, { createContext, useState } from "react";

export const RegisterContext = createContext();

const RegisterProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isOtpVerified, setOtpVerified] = useState(false);
  const [canResendOtp, setCanResendOtp] = useState(false);

  return (
    <RegisterContext.Provider
      value={{
        user,
        setUser,
        isOtpVerified,
        setOtpVerified,
        canResendOtp,
        setCanResendOtp,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterProvider;
