import { ActionIcon, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { Icon } from "@iconify/react";
import { useDeleteMasterAccountMutation } from "../../../services/account/accounts.service";
import { reFetchTableData1 } from "../../../utils/functions";

const DeleteMasterAccountModal = ({ accountData, refetch }) => {
  const [deleteMasterAccount, { isLoading: deleteLoading }] =
  useDeleteMasterAccountMutation();

  const onDelete = async () => {
    await deleteMasterAccount(accountData?.id);
    closeAllModals();
    if (refetch) {
      refetch()
    } else {
      reFetchTableData1();
    }
  };
  
  return (
    <div>
      <div className="flex items-center justify-center mb-10">
        <ActionIcon c="red" bg="red.1" variant="light" size={100} radius="100%">
          <Icon width={60} icon="pepicons-print:question" />
        </ActionIcon>
      </div>
      <div className="mt-5 text-center">
        <h2 className="mb-3">Are you sure ?</h2>
        <h3>
          Do you really want to delete{" "}
          <span className="text-red-500">{accountData?.broker_id}</span> ?
        </h3>
        <div className="flex mt-10 gap-4">
          <Button
            fullWidth
            text="Delete"
            type="submit"
            onClick={onDelete}
            loading={deleteLoading}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="outline"
            type="button"
            onClick={closeAllModals}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMasterAccountModal;
