import { Box, Image } from "@mantine/core";
import { Link } from "react-router-dom";
import { BASE_LINK } from "../../routes/public.routes.jsx";

import mainLogo from "../../assets/image/OcctaBot_logo.png";

const Logo = ({ size = 150 }) => {
  return (
    <Box component={Link} to={BASE_LINK.BASE} size="xl">
      <Image w={size} src={mainLogo} />
    </Box>
  );
};
export default Logo;
