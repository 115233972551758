import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { reFetchTableData } from "../../../../utils/functions";
import {
  useAddBrokerAccountMutation,
  useUpdateBrokerAccountMutation,
} from "../../../../services/account/broker.service";

const FyersAccountCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const authCode = searchParams.get("auth_code");

  const [addBrokerAccount] = useAddBrokerAccountMutation();

  const [updateBrokerAccount] = useUpdateBrokerAccountMutation();

  useEffect(() => {
    if (authCode) {
      const vData =
        localStorage.getItem("addFyersAccountData") &&
        JSON.parse(atob(localStorage.getItem("addFyersAccountData")));
      const postData = { ...vData, authCode };
      addUpdateFyersAccount(postData);
    }
  }, [authCode]);

  const addUpdateFyersAccount = async (vData) => {
    if (vData?.id) {
      await updateBrokerAccount(vData).then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          reFetchTableData();
        }
      });
      navigate("/dashboard");
    } else {
      await addBrokerAccount(vData).then((res) => {
        console.log("res", res);
        if (res?.status === 201 || res?.status === 200) {
          reFetchTableData();
        }
      });
      navigate("/dashboard");
    }
  };

  return <div></div>;
};

export default FyersAccountCallback;
