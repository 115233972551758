import { ActionIcon, ScrollArea, Text, Tooltip } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { IconPackages } from "@tabler/icons-react";

const ConsoleAction = ({
  title = "",
  size = 1200,
  icon = <IconPackages stroke={1.5} />,
  component = null,
  color = "orange",
}) => {
  return (
    <div>
      <Tooltip
        label={title}
        color={color}
        arrowSize={12}
        withArrow
        transitionProps={{ transition: "pop", duration: 200 }}
      >
        <ActionIcon
          onClick={() => {
            openModal({
              id: `${title}_popup_id`,
              centered: true,
              closeOnClickOutside: false,
              title: (
                <div className="flex items-center gap-2">
                  {icon}
                  <Text component="span" fw="bold" fz={20} lineClamp={1}>
                    {title}
                  </Text>
                </div>
              ),
              transitionProps: { transition: "pop" },
              children: <div>{component}</div>,
              size: size,
              scrollAreaComponent: ScrollArea.Autosize,
              styles: {
                header: {
                  boxShadow: "var(--mantine-shadow-sm)",
                },
                body: {
                  padding: `20px 25px 25px 25px`,
                },
              },
            });
          }}
          variant="subtle"
          size="md"
          w={40}
          h={40}
        >
          {icon}
        </ActionIcon>
      </Tooltip>
    </div>
  );
};

export default ConsoleAction;
