import React from "react";
import { Button, Image } from "@mantine/core";
import { Link } from "react-router-dom";
import introBg from "../../../assets/image/1.svg";
import MultiColorDots from "../../heading/MultiColorDots.jsx";

const IntroAbout = () => {
  return (
    <section className="py-20 bg-white shadow drop-shadow-sm">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div>
            <MultiColorDots centered={false} />
            <h2 className="text-4xl leading-[1.4] mb-5">
              Meet{" "}
              <span className="text-[var(--mantine-primary-color-filled)]">
                Our Company
              </span>{" "}
              Unless Four Pillar of Occtabot
            </h2>
            <div className="mb-5">
              <h3 className="text-[var(--mantine-primary-color-filled)]">1. ALGO Trading</h3>
              <p className="text-lg">
              We have ALGO with high win ratio & risk : reward
              ratio for all segment. Like Nifty, Banknifty, Stocks,
              Option Buying ,Option Selling etc…
              </p>
            </div>
            <div className="mb-5">
              <h3 className="text-[var(--mantine-primary-color-filled)]">2. Scanner Nifty 100 Stocks</h3>
              <p className="text-lg">
              We have developed scanner and Indicators for momentum stocks for swing, option
              Buying & option selling trading with high win ratio.
              </p>
            </div>
            <div className="mb-5">
              <h3 className="text-[var(--mantine-primary-color-filled)]">3. Scalping Course</h3>
              <p className="text-lg">
              We have developed Indicators for scalping trading with high accuracy with
              consistently profitable trade. Also we have provided indicators for Scalping set up & Intraday trading.
              </p>
            </div>
            <div className="mb-5">
              <h3 className="text-[var(--mantine-primary-color-filled)]">4. Excel Tool</h3>
              <p className="text-lg">
              We have developed Excel sheet for Stocks. Its give 10 years back testing results for
              300 stocks. This is for swing trade, Option and future.
              </p>
            </div>
            <div className="mt-10">
              <Button component={Link} to="/about" color="green" size="lg">
                About Us
              </Button>
            </div>
          </div>
          <div>
            <Image src={introBg} alt="About Us" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroAbout;
