import { Button } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { closeAllModals } from "@mantine/modals";

import SelectInput from "../../elements/SelectInput.jsx";
import {
  MasterAccountDefaultValue,
  MasterAccountSchema,
} from "./masterAccount.schema.js";
import {
  useAddMasterAccountMutation,
  useGetAccountListsQuery,
  useGetMasterAccountListsQuery,
} from "../../../services/account/accounts.service.js";
import SwitchInput from "../../elements/SwitchInput.jsx";

const AddMasterAccountForm = () => {
  const { data: accountLists } = useGetAccountListsQuery();

  const { data: masteAccountList, refetch } = useGetMasterAccountListsQuery();

  const [addMasterAccount, { isLoading: isBaAddLoading }] =
    useAddMasterAccountMutation();

  const methods = useForm({
    resolver: yupResolver(MasterAccountSchema),
    mode: "onBlur",
    defaultValues: MasterAccountDefaultValue,
  });

  const handleBrokerForm = async (values) => {
    const vData = { ...values, accountType: "master", copy_trade: true };

    await addMasterAccount(vData).then((res) => {
      closeAllModals();
      refetch();
      if (res?.data?.status || res?.status === 201 || res?.status === 200) {
        closeAllModals();
      }
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleBrokerForm)}>
          <div className="grid grid-cols-1 gap-2">
            <SelectInput
              label="Accounts"
              name="clientId"
              data={accountLists?.dropdown}
              props={{
                withAsterisk: true,
                required: true,
                allowDeselect: false,
                size: "lg",
                nothingFoundMessage: "No account found",
              }}
            />
            <div className="mt-5">
              <SwitchInput name="trade" label="Trade" />
            </div>
            <div className="mt-5 flex items-center gap-2 gap-x-4">
              <Button
                size="lg"
                fullWidth
                type="submit"
                disabled={!methods.formState.isValid || isBaAddLoading}
              >
                Add Account
              </Button>
              <Button
                size="lg"
                color="black"
                fullWidth
                type="button"
                onClick={closeAllModals}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddMasterAccountForm;
