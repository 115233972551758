import { Icon } from "@iconify/react";
import { Button } from "@mantine/core";
import { redirectToFyersAuthLink } from "../../../utils/brokers";

const ConnectToAccount = ({ brokerData }) => {
  console.log("brokerData", brokerData);

  const onAccountConnect = async () => {
    if (brokerData?.broker_type === "fyers") {
      localStorage.setItem(
        "addFyersAccountData",
        btoa(JSON.stringify(brokerData))
      );
      redirectToFyersAuthLink(brokerData);
    }
  };

  if (brokerData?.broker_type === "angelone") {
    return null;
  }

  return (
    <Button
      variant='subtle'
      className={`!p-1 w-0 !h-auto mr-[-10px]`}
      title='Connect to Account'
      onClick={onAccountConnect}
    >
      <Icon icon='material-symbols:login-sharp' width={20} height={20} />
    </Button>
  );
};

export default ConnectToAccount;
