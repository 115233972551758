import PopupContext from "../popup/PoupContext.jsx";
import AddBrokerAccountForm from "../../forms/entities/broker/AddBrokerAccountForm.jsx";
import GlobalTable from "../table/GlobalTable.jsx";
import ActionButtonWrapper from "../table/general/action/ActionButtonWrapper.jsx";
import DeleteBrokerAccountModal from "../../forms/entities/broker/DeleteBrokerAccountModal.jsx";
import AccountListColumns from "./columns/AccountListColumns.jsx";
import ActionButton from "../table/general/action/ActionButton.jsx";
import DownloadData from "./component/DownloadData.jsx";
import ConnectToAccount from "./component/ConnectToAccount.jsx";

const BrokerLists = () => {
  return (
    <div>
      <GlobalTable
        heading='Broker Lists'
        columns={AccountListColumns}
        endpoint='/broker/get-accounts-list/'
        tableProps={{
          enableRowNumbers: false,
          enablePagination: false,
          enableBottomToolbar: false,
          enableFilters: false,
          enableFullScreenToggle: false,
          enableGlobalFilter: false,
          enableGrouping: false,
          enableSorting: false,
          renderRowActions: ({ row }) => {
            return (
              <ActionButtonWrapper>
                <ConnectToAccount brokerData={row.original} />
                <ActionButton
                  edit={{
                    isPopup: true,
                    popupTitle: "Edit Broker Account",
                    component: (
                      <AddBrokerAccountForm brokerData={row.original} />
                    ),
                  }}
                  remove={{
                    isPopup: true,
                    popupTitle: "Delete Broker Account",
                    component: (
                      <DeleteBrokerAccountModal brokerData={row.original} />
                    ),
                  }}
                />
              </ActionButtonWrapper>
            );
          },
        }}
        rightAction={
          <div className='flex items-center justify-end gap-2'>
            <DownloadData />
            <PopupContext
              component={<AddBrokerAccountForm />}
              modelTitle='Add Account'
              buttonLabel='Add Account'
            />
          </div>
        }
      />
    </div>
  );
};

export default BrokerLists;
