import React from "react";

const MultiColorDots = ({ centered = true }) => {
  return (
    <div
      className={`flex items-center gap-2 mb-5 ${centered && "justify-center"}`}
    >
      <span className="bg-teal-500 w-4 h-4 inline-block rounded-full shadow drop-shadow"></span>
      <span className="bg-orange-500 w-4 h-4 inline-block rounded-full shadow drop-shadow"></span>
      <span className="bg-pink-500 w-4 h-4 inline-block rounded-full shadow drop-shadow"></span>
      <span className="bg-teal-500 w-4 h-4 inline-block rounded-full shadow drop-shadow"></span>
      <span className="bg-indigo-500 w-4 h-4 inline-block rounded-full shadow drop-shadow"></span>
    </div>
  );
};

export default MultiColorDots;
