import GoToTop from "../utilities/GoToTop.jsx";
import Logo from "./Logo.jsx";
import SocialMedia from "../social/SocialMedia.jsx";

const Footer = () => {
  return (
    <div className="bg-gray-100 border-t">
      <div className="w-full py-20">
        <GoToTop />
        <div className="container">
          <div className="grid grid-cols-1 gap-5 items-center">
            <div className="w-full max-w-[510px] mx-auto text-center">
              <div className="mb-5">
                <div className="flex items-center justify-center">
                  <Logo size={200} />
                </div>
                <p className="mt-5 font-bold">
                  Our company is all about creating innovative solutions and
                  providing top-notch services to our clients. From start to
                  finish, we're dedicated to delivering results that exceed
                  expectations.
                </p>
              </div>
              <div>
                <h3 className="mb-3">Follow us on Social Media</h3>
                <div className="flex items-center justify-center">
                  <SocialMedia />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-t py-3 text-center shadow-sm drop-shadow-sm">
        <p className="font-bold">
          Copyright &copy; {new Date().getFullYear()}. All rights reserved.
        </p>
        <p className="text-gray-300">Design and developed by ETS</p>
      </div>
    </div>
  );
};
export default Footer;
