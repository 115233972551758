import * as Yup from "yup";

export const emailRegex = /.+@[^@]+\.[^@]{2,}$/;
export const passRegex =
  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])[a-zA-Z0-9!#$@^%&?]{6,25}$/;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegistrationValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required.")
    .min(2, "Username must be at least 2 characters long.")
    .max(50, "Username must not exceed 50 characters.")
    .matches(
      /^[a-zA-Z0-9!@#$%^'.&* ]*$/,
      "Username must contain only alphabet characters.",
    ),
  email: Yup.string()
    .required("Email is required.")
    .matches(emailRegex, "Invalid email."),
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Minimum 6 characters are required")
    .max(25, "Max 25 characters are shouldAllow")
    .matches(passRegex, "Please enter strong password."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password doesn't match.",
  ),
  phone: Yup.string()
    .required("Mobile number is required.")
    .matches(phoneRegExp, "Invalid Mobile number.")
    .min(10, "Mobile number must be 10 digit number")
    .max(10, "Mobile number can not be greater than 10 digit"),
});

export const LoginValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("User Name is required.")
    .min(2, "User Name must be at least 2 characters long.")
    .max(50, "User Name must not exceed 50 characters.")
    .matches(
      /^[a-zA-Z0-9!@#$%^'.&* ]*$/,
      "User Name must contain only alphabet characters.",
    ),
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Minimum 6 characters are required")
    .max(25, "Max 25 characters are shouldAllow")
    .matches(passRegex, "Please enter strong password."),
});

export const OTPValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Otp is required.")
    .min(6, "Minimum 6 characters are required"),
});

// Forgot Password validation schema
export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Email is required.")
    .matches(emailRegex, "Invalid email."),
});

//
export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Minimum 6 characters are required")
    .max(25, "Max 25 characters are shouldAllow")
    .matches(passRegex, "Please enter strong password."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password doesn't match.",
  ),
});

// Forgot Password initial states
export const forgotPasswordInitial = {
  email: "",
};

// Registration Initial States
export const RegisterInitialValue = {
  username: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
};

// Otp Initial States
export const verifyOTPInitial = {
  otp: "",
};

// Reset Password Initial States
export const resetPasswordInitial = {
  token: "",
  password: "",
  confirmPassword: "",
};
