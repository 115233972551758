import theme from "./theme/index.js";
import "react-toastify/dist/ReactToastify.css";
import { localStorageColorSchemeManager, MantineProvider } from "@mantine/core";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { authRoutes } from "./routes/auth.routes.jsx";
import { PUBLIC_LINKS, publicRoutes } from "./routes/public.routes.jsx";
import { dashboardRoutes } from "./routes/dashboard.routes.jsx";

import MasterLayout from "./layouts/MasterLayout.jsx";
import ScrollTop from "./components/common/ScrollTop.jsx";
import DashboardLayout from "./layouts/DashboardLayout.jsx";
import PublicLayout from "./layouts/PublicLayout.jsx";
import AuthLayout from "./layouts/AuthLayout.jsx";
import { ModalsProvider } from "@mantine/modals";
import { useGetUserProfileQuery } from "./services/account/accounts.service.js";

const App = () => {
  const colorSchemeManager = localStorageColorSchemeManager({
    key: "color-scheme",
  });

  const {
    data: userDetails
  } = useGetUserProfileQuery();

  const isUserSubscribed = () => {
    return userDetails?.subscription?.isSubscriptionActive;
  };

  // const ProtectedRoute = ({ element, path }) => {
  //   return isUserSubscribed() ? (
  //     <Route path={path} element={element} />
  //   ) : (
  //     <Navigate to={PUBLIC_LINKS?.PLANS} replace />
  //   );
  // };

  return (
    <MantineProvider
      theme={theme}
      withCssVariables
      defaultColorScheme="light"
      classNamesPrefix="rdph"
      colorSchemeManager={colorSchemeManager}
    >
      <ScrollTop />
      <ToastContainer
        transition={Slide}
        limit={1}
        theme="colored"
        autoClose={1000}
        closeButton={false}
        pauseOnHover
        closeOnClick
        hideProgressBar
        draggablePercent={60}
      />
      <ModalsProvider>
        <Routes>
          {/* Master Layout */}
          <Route element={<MasterLayout />}>
            {/* Authentication Routes */}
            <Route element={<AuthLayout />}>
              {authRoutes.map((route, i) => {
                return (
                  <Route key={i} path={route.path} element={route.element} />
                );
              })}
            </Route>

            {/* Public Routes */}
            <Route element={<PublicLayout />}>
              {publicRoutes.map((route, i) => {
                return (
                  <Route key={i} path={route.path} element={route.element} />
                );
              })}
            </Route>

            {/* Dashboard and Protected Routes */}
            <Route element={<DashboardLayout />}>
              {dashboardRoutes.map((route, i) => {
                return (
                  <Route key={i} path={route.path} element={route.element} />
                  // <ProtectedRoute key={i} path={route.path} element={route.element} />
                );
              })}
            </Route>
          </Route>
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  );
};
export default App;
