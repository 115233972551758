import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access: "",
  refresh: "",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    SET_AUTH_TOKEN: (state, action) => ({
      ...state,
      access: action.payload.data?.accessToken,
      refresh: action.payload.data?.refreshToken,
    }),
    RESET_AUTH_TOKEN: () => initialState,
  },
});

export const { SET_AUTH_TOKEN, RESET_AUTH_TOKEN } = tokenSlice.actions;

export const GET_AUTH_TOKEN = (state) => state.app.auth.token;
