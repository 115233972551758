import { Link } from "react-router-dom";
import LoginForm from "../../forms/entities/auth/LoginForm.jsx";
import { authRouteLinks } from "../../routes/auth.routes.jsx";

const LoginPage = () => {
  return (
    <div>
      <LoginForm />
      <div className="mt-5 text-center flex flex-col items-center justify-center gap-3">
        <Link
          className="w-full block hover:text-blue-700"
          to={authRouteLinks.fPassword.path}
        >
          Forgot Password ?
        </Link>
        <div className="text-center flex items-center flex-wrap justify-center gap-2">
          <span>Don't have an account ?</span>
          <Link
            className="block hover:text-blue-700"
            to={authRouteLinks.register.path}
          >
            Register here
          </Link>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
