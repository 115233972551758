import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  created_at: "",
  email: "",
  password: "",
  phone: "",
  role: "",
  updated_at: "",
  user_id: null,
  username: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_AUTH_USER: (state, action) => ({
      ...state,
      ...action.payload.data?.user,
    }),
    RESET_AUTH_USER: () => initialState,
  },
});

export const { SET_AUTH_USER, RESET_AUTH_USER } = userSlice.actions;

export const GET_AUTH_USER = (state) => state.app.auth.user;
