import React from "react";
import { IconFaceIdError } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <section className="flex items-center justify-center min-h-screen">
      <div className="container">
        <div className="grid grid-cols-1">
          <div className="text-center">
            <div className="flex items-center text-red-500 justify-center mb-10">
              <IconFaceIdError size="100" stroke={1} />
            </div>
            <h1 className="text-4xl mb-5">Ooops! Something went wrong</h1>
            <p className="text-xl mb-10">
              The page you're looking for has been moved or not available at the
              moment.
            </p>
            <div className="flex items-center justify-center gap-4">
              <Button component={Link} to="/">
                Go Home
              </Button>
              <Button variant="light" onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
