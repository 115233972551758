import { plansPackages } from "./PlansConstant.jsx";
import { ActionIcon, Button, Image, Paper } from "@mantine/core";
import { IconBasket, IconChevronRight } from "@tabler/icons-react";
import PricingPlanCustom from "./PricingPlanCustom.jsx";
import { useSelector } from "react-redux";
import { GET_AUTH_CONFIG } from "../../../redux/auth/config.slice.js";
import { useGetProductListsQuery } from "../../../services/account/payment.service.js";

const PricingPlan = () => {
  const { isLoggedIn } = useSelector(GET_AUTH_CONFIG);

  const { data: productList } = useGetProductListsQuery();

  console.log('productList', productList)

  return (
    <div className="grid sm:grid-cols-3 gap-5 my-16">
      {plansPackages.map((plan, i) => {
        return (
          <div key={i} className="">
            <Paper
              shadow="xs"
              radius="sm"
              p="lg"
              key={i}
              w="100%"
              pos="relative"
            >
              <div className="absolute right-4 top-1">
                <h5>{plan.price}</h5>
                <h3 className="font-bold text-[35px]">{plan.offer_price}</h3>
              </div>
              <div className="mb-3">
                <Image w={80} src={plan.icon} alt={plan.name} />
              </div>
              <h3 className="font-bold">{plan.name}</h3>
              <ul className="flex flex-col gap-2 mt-5">
                {plan.features.map((feature, i) => {
                  return (
                    <li key={i} className="flex items-center gap-2">
                      <div className="w-[30px]">
                        <ActionIcon
                          variant="gradient"
                          radius="50%"
                          gradient={{ from: "orange", to: "yellow", deg: 90 }}
                        >
                          <IconChevronRight size={16} stroke={1.5} />
                        </ActionIcon>
                      </div>
                      <div
                        className="w-[calc(100%-30px)] text-[15px] font-semiBold"
                        dangerouslySetInnerHTML={{ __html: feature }}
                      />
                    </li>
                  );
                })}
              </ul>
              {
                isLoggedIn ? (
                  <div className="mt-5">
                    <Button
                      leftSection={<IconBasket />}
                    >
                      Buy now
                    </Button>
                </div>
                ) : (
                  <div className="mt-5">
                    <Button
                      href={'/register'}
                    >
                      Sign Up
                    </Button>
                  </div>
                )
              }
              
            </Paper>
          </div>
        );
      })}
      {/* <div>
        <Paper shadow="xs" radius="sm" p="lg" w="100%">
          <PricingPlanCustom />
        </Paper>
      </div> */}
    </div>
  );
};

export default PricingPlan;
