import { openModal } from "@mantine/modals";
import { Button, ScrollArea, Text } from "@mantine/core";
import { Icon } from "@iconify/react";

const PopupContext = (props) => {
  const {
    fullwidth = false,
    modelTitle = "",
    buttonLabel,
    buttonIcon = "",
    popupClass = "global-modal",
    component,
    customClass,
    variant = "filled",
    iconSize = 20,
    size = "lg",
    btnProps,
  } = props;

  return (
    <Button
      fullWidth={fullwidth}
      variant={variant}
      className={customClass}
      data-cy={`${buttonLabel
        ?.toLowerCase()
        .replaceAll(" ", "_")}_popup_context_btn`}
      id={`${buttonLabel
        ?.toLowerCase()
        .replaceAll(" ", "_")}_popup_context_btn_id`}
      onClick={() => {
        openModal({
          id: `${buttonLabel?.toLowerCase().replaceAll(" ", "_")}_popup_id`,
          centered: true,
          closeOnClickOutside: false,
          title: (
            <Text component="span" fw="bold" fz={20} lineClamp={1}>
              {modelTitle}
            </Text>
          ),
          transitionProps: { transition: "pop" },
          children: <div>{component}</div>,
          className: popupClass,
          size: size ? size : "550px",
          scrollAreaComponent: ScrollArea.Autosize,
          styles: {
            header: {
              boxShadow: "var(--mantine-shadow-sm)",
            },
            body: {
              padding: `20px 25px 25px 25px`,
            },
          },
        });
      }}
      {...btnProps}
    >
      <Icon icon={buttonIcon} width={iconSize} height={iconSize} />
      {buttonLabel && <span>{buttonLabel}</span>}
    </Button>
  );
};

export default PopupContext;
