import icon1 from "../../../assets/image/icon/1.svg";
import icon2 from "../../../assets/image/icon/2.svg";
import icon3 from "../../../assets/image/icon/3.svg";
import icon4 from "../../../assets/image/icon/4.svg";

export const services = [
  {
    image: icon1,
    name: "Algo Trading",
  },
  {
    image: icon2,
    name: "Copy Trading"
  },
  {
    image: icon3,
    name: "Indicator Service"
  },
  {
    image: icon4,
    name: "24 * 7 Support"
  },
  {
    image: icon3,
    name: "Technical Analysis"
  },

  {
    image: icon1,
    name: "Develop Your Own Strategy"
  },
  {
    image: icon4,
    name: "Premium What's App Group"
  },
  {
    image: icon3,
    name: "Scalping Strategy Course"
  },
  {
    image: icon3,
    name: 'Tradingview Pine Code Course'
  },
  {
    image: icon3,
    name: 'Open Free Demat Account'
  }
];
