import { Button } from "@mantine/core";
import useCountdownTimer from "../../../hooks/useCountdownTimer.js";
import { useSingleEffect, useUpdateEffect } from "react-haiku";
import { useSendOTPMutation } from "../../../services/auth/auth.services.js";
import { toast } from "react-toastify";
import { useContext } from "react";
import { RegisterContext } from "./register/RegisterProvider.jsx";

const ResendOTP = ({ mobile, isSuccess, props, userId }) => {
  const { setCanResendOtp, canResendOtp } = useContext(RegisterContext);
  const [sendOTP, { isSuccess: sendOptSuccess }] = useSendOTPMutation();
  const timer = useCountdownTimer(120);

  const handleResendOTP = async () => {
    await sendOTP({
      mobileNumber: `+91${mobile}`,
      userId: userId,
      reSend: true,
    })
      .unwrap()
      .then(() => {
        toast.success("An OTP has been re-sent");
        setCanResendOtp(true);
        setTimeout(() => {
          timer.startCountdown();
        }, 100);
      });
  };

  useSingleEffect(() => {
    if (!timer.isActive) {
      timer.startCountdown();
    }
  })

  // Start the timer when the countdown is started
  useUpdateEffect(() => {
    if (isSuccess) {
      timer.startCountdown();
    } else if (sendOptSuccess) {
      timer.resetCountdown();
    }
  }, [isSuccess, sendOptSuccess]);

  useUpdateEffect(() => {
    if (!timer.isActive) {
      setCanResendOtp(false);
    }
  }, [!timer.isActive]);

  return (
    <Button
      type="button"
      variant="outline"
      disabled={timer.isActive || canResendOtp}
      {...props}
      onClick={handleResendOTP}
    >
      {timer.isActive ? `${timer.minutes} : ${timer.seconds}` : "Resend OTP"}
    </Button>
  );
};
export default ResendOTP;
