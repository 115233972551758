import { useGetPNLListQuery } from '../../../services/account/broker.service';
import { Table, Text } from '@mantine/core';
import DownloadPnl from './DownloadPnl';

const PNLLists = ({accountData}) => {
    const { data: pnlList } = useGetPNLListQuery(accountData?.id, {skip: !accountData?.id});

    const rows = pnlList?.data?.map((order) => (
      <Table.Tr key={order?.clientId}>
        <Table.Td>{order?.clientId}</Table.Td>
        <Table.Td ta="center">{order?.netPosition}</Table.Td>
        <Table.Td ta="center">{order?.pnl}</Table.Td>
      </Table.Tr>
    ));
  
    return (
      <div>
        <div className="flex items-center justify-between gap-5 mb-4">
          <Text component="h3" fz={20} lineClamp={1} fw="bold">
            ALL POSITIONS
          </Text>
          <DownloadPnl accountData={accountData} />
        </div>
         <Table.ScrollContainer maw={1100} h={400} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
          <Table
            withTableBorder
            withRowBorders
            withColumnBorders
            highlightOnHover
            striped
            verticalSpacing="xs"
            horizontalSpacing="xs"
            stickyHeader
            styles={{
              thead: {
                boxShadow: "var(--mantine-shadow-xs)",
                background: "var(--mantine-color-default)",
                zIndex: 9,
              },
              th: {
                fontSize: "var(--mantine-font-size-sm)",
              },
            }}
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>CLIENT ID</Table.Th>
                <Table.Th ta="center">NET POSITION</Table.Th>
                <Table.Th ta="center">DAY'S P&L</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </div>
    )
}

export default PNLLists
