import { Button, Image } from "@mantine/core";
import { IconBell } from "@tabler/icons-react";
import homeBg from "../../../assets/image/1.png";
import homePattern from "../../../assets/image/pattern.png";
import { Link } from "react-router-dom";
import SocialMedia from "../../social/SocialMedia.jsx";

const Welcome = () => {
  return (
    <section className="py-20 flex items-center justify-center relative">
      <div className="min-h-[calc(100vh-95px)] inset-0 flex items-center justify-center w-full absolute overflow-hidden">
        <img src={homePattern} alt="" />
      </div>
      <div className="container">
        <div className="grid md:grid-cols-2 items-center gap-5">
          <div className="max-w-[650px] w-full">
            <div className="bg-white font-extraBold text-black inline-flex px-5 shadow py-1.5 rounded gap-3 mb-5">
              <span className="text-blue-700">
                <IconBell stroke={1.5} />
              </span>
              <span>Discover a new ways to enjoys your trading with Occtabot!</span>
            </div>
            <h1 className="mb-5 text-5xl leading-[1.2]">
              Strategies , Indicators & Scanners with high win ratio
            </h1>
            <p className="text-xl mb-10 max-w-[550px]">
                We have developed Strategies , Indicators & Scanners to find Nifty 100 stocks based on Fundamental + Technical
                Analysis. Best platform for copy & ALGO Trade.
            </p>
            <div className="flex items-center gap-4">
              <Button size="lg" component={Link} to="/services">
                Learn More
              </Button>
              <Button
                size="lg"
                variant="light"
                color="pink"
                component={Link}
                to="/contact"
              >
                Contact Us
              </Button>
            </div>
            <div className="mt-8">
              <h3 className="mb-5">Follow us</h3>
              <SocialMedia />
            </div>
          </div>
          <div>
            <Image src={homeBg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
