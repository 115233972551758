const ChildLastOrderDetails = (cellData) => {
    const accountDetails = cellData?.rowData?.original;
    return (
        <div>
        <p>{accountDetails?.lastOrder?.updatetime}</p>
        <p>{accountDetails?.lastOrder?.status}</p>
        </div>
    )
}

export default ChildLastOrderDetails
