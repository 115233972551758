import Heading from "../../components/heading/Heading.jsx";
import ServiceCard from "../../components/pages/services/ServiceCard.jsx";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";

const ServicePage = () => {
  return (
    <section className='py-10'>
      <div className='container'>
        <div className='grid grid-cols-1'>
          <div className='flex items-center justify-center text-center max-w-[650px] mx-auto'>
            <Heading description='Top Categories' title='CATEGORIES' />
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 my-16'>
          <ServiceCard />
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-center gap-5 mb-5 sm:max-w-[550px] sm:mx-auto'>
          <Button
            component={Link}
            to='/contact'
            variant='light'
            color='pink'
            size='lg'
            rightSection={<IconChevronRight stroke={1.5} />}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ServicePage;
