import RegisterForm from "../../forms/entities/auth/register/RegisterForm.jsx";
import { useContext } from "react";
import { RegisterContext } from "../../forms/entities/auth/register/RegisterProvider.jsx";
import VerifyOTPForm from "../../forms/entities/auth/register/VerifyOTPForm.jsx";
import RegisterSuccess from "../../forms/entities/auth/register/RegisterSuccess.jsx";

const RegisterPage = () => {
  const { user, isOtpVerified } = useContext(RegisterContext);
  return (
    <div>
      {!user?.user_id ? (
        <RegisterForm />
      ) : !isOtpVerified ? (
        <VerifyOTPForm />
      ) : (
        <RegisterSuccess />
      )}
    </div>
  );
};

export default RegisterPage;
