import { Icon } from "@iconify/react";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { authRouteLinks } from "../../../../routes/auth.routes.jsx";

const RegisterSuccess = () => {
  return (
    <div className="grid grid-cols-1 gap-3">
      <div className="text-center mb-5">
        <div className="flex items-center justify-center text-green-500">
          <Icon width={70} icon="icon-park-solid:check-one" />
        </div>
        <h3 className="text-4xl my-5">Thank you !</h3>
        <h6 className="text-xl mb-8">
          Your account has been verified successfully. You can now login to
          dashboard.
        </h6>

        <Button
          component={Link}
          to={authRouteLinks.login.path}
          color="green"
          type="submit"
        >
          Go to Login
        </Button>
      </div>
    </div>
  );
};
export default RegisterSuccess;
