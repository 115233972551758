import { Icon } from "@iconify/react";
import { Button } from "@mantine/core";
import { openModal } from "@mantine/modals";
import DeleteMasterAccountModal from "./DeleteMasterAccountModal";

const MasterTableActionButton = ({ account, refetch }) => {
	
	return (
		<div className="flex items-center justify-center gap-0">
				<Button
					onClick={() => {
						openModal({
						size: "md",
						centered: true,
						closeOnEscape: false,
						closeOnClickOutside: false,
						title: <div className="font-bold text-2xl">Delete Master Account</div>,
						children: <DeleteMasterAccountModal accountData={account} refetch={refetch} />,
						});
					}}
					variant="subtle"
					className={`!p-1 w-0 !h-auto`}>
					<Icon width={20} icon="ph:trash-light" />
				</Button>
			</div>
	);
};

export default MasterTableActionButton;
