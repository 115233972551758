import {
  Button,
  createTheme,
  Modal,
  NumberInput,
  PasswordInput,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import modalCss from "../theme/modal.module.css";

const theme = createTheme({
  fontFamily: "var(--base-font)",
  primaryColor: "orange",
  primaryShade: 7,
  cursorType: "pointer",
  components: {
    Button: Button.extend({
      defaultProps: {
        size: "md",
        fw: "bold",
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: "md",
      },
      styles: {
        label: {
          fontSize: 15,
          marginBottom: 5,
          fontWeight: 600,
        },
        error: {
          fontSize: 12,
        },
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        size: "md",
      },
      styles: {
        label: {
          fontSize: 15,
          marginBottom: 5,
          fontWeight: 600,
        },
        error: {
          fontSize: 12,
        },
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        size: "md",
      },
      styles: {
        label: {
          fontSize: 15,
          marginBottom: 5,
          fontWeight: 600,
        },
        error: {
          fontSize: 12,
        },
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        size: "md",
      },
      styles: {
        label: {
          fontSize: 15,
          marginBottom: 5,
          fontWeight: 600,
        },
        error: {
          fontSize: 12,
        },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        size: "md",
      },
      styles: {
        input: {
          fontSize: 17,
        },
        option: {
          fontSize: 15,
        },
        label: {
          fontSize: 15,
          marginBottom: 5,
          fontWeight: 600,
        },
        error: {
          fontSize: 12,
        },
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        size: "md",
      },
      classNames: modalCss,
    }),
  },
});

export default theme;
