import MultiColorDots from "./MultiColorDots.jsx";

const Heading = ({ title, description }) => {
  return (
    <div>
      <div>
        <MultiColorDots />
      </div>
      <h3 className="text-4xl mb-5">{title}</h3>
      <p className="text-lg">{description}</p>
    </div>
  );
};

export default Heading;
