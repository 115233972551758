import { IconCurrencyRupee } from "@tabler/icons-react";
import ProIcon from "../../../assets/image/icon/5.svg";
import EntIcon from "../../../assets/image/icon/6.svg";
import CustomizedPlanIcon from "../../../assets/image/icon/7.svg";

export const plansPackages = [
  {
    icon: ProIcon,
    name: "Basic",
    stripeName: 'Professional',
    price: (
      <div className="flex justify-end text-gray-300 font-normal text-[14px] items-center gap-1 line-through">
        <IconCurrencyRupee size={20} /> 1799
      </div>
    ),
    offer_price: (
      <div className="flex items-center gap-2">
        <IconCurrencyRupee /> 1499
      </div>
    ),
    info: "+18% GST applicable",
    validity: "for 30 days",
    features: [
      "Auto Login",
      "Copy Trading Upto 9 Accounts </br> (Excluding Master)",
      "1 Master Account",
    ],
    buyUrl: "/redirect-to-stripe",
  },
  {
    icon: EntIcon,
    name: "Pro+",
    stripeName: 'Enterprise',
    price: (
      <div className="flex justify-end text-gray-300 font-normal text-[14px] items-center gap-1 line-through">
        <IconCurrencyRupee size={20} /> 2999
      </div>
    ),
    offer_price: (
      <div className="flex items-center gap-2">
        <IconCurrencyRupee /> 2499
      </div>
    ),
    info: "+18% GST applicable",
    validity: "for 30 days",
    features: [
      "Auto Login",
      "Copy Trading Upto 24 Accounts </br> (Excluding Master)",
      "2 Master Account",
    ],
    buyUrl: "/redirect-to-stripe",
  },
  {
    icon: CustomizedPlanIcon,
    name: "Premium",
    stripeName: 'Premium',
    price: (
      <div className="flex justify-end text-gray-300 font-normal text-[14px] items-center gap-1 line-through">
        <IconCurrencyRupee size={20} /> 2999
      </div>
    ),
    offer_price: (
      <div className="flex items-center gap-2">
        <IconCurrencyRupee /> 2499
      </div>
    ),
    info: "+18% GST applicable",
    validity: "for 30 days",
    features: [
      "Auto Login",
      "Copy Trading Unlimited user",
      "5 Master Account",
    ],
    buyUrl: "/redirect-to-stripe",
  },
];
