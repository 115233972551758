import { useRef } from "react";
import { PinInput } from "@mantine/core";
import { Controller, useFormContext } from "react-hook-form";

const InputPin = ({ name, label, props }) => {
  const inputRef = useRef(null);
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <div className="w-full flex justify-center items-center flex-col">
            {label && <div className="font-bold mb-2 text-[14px]">{label}</div>}
            <PinInput
              ref={inputRef}
              size="md"
              inputMode="numeric"
              inputType="number"
              error={error?.message}
              onBlur={onBlur}
              value={value}
              onChange={(newValue) => onChange(newValue)}
              autoComplete="off"
              {...props}
            />
          </div>
        );
      }}
    />
  );
};

export default InputPin;
