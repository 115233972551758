import { Burger, Group } from "@mantine/core";
import Logo from "./Logo.jsx";
import UserMenu from "../user/UserMenu.jsx";
import SwitchDashboard from "./SwitchDashboard.jsx";
import HeaderLinks from "./HeaderLinks.jsx";

const DHeader = ({ toggle, opened }) => {
  return (
    <Group h="100%" px="md">
      <Group justify="space-between" style={{ flex: 1 }}>
        <Logo />
        <Group ml="xl" gap={20} visibleFrom="sm">
          <HeaderLinks />
          <SwitchDashboard />
          <UserMenu />
        </Group>
        <Group hiddenFrom="sm">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        </Group>
      </Group>
    </Group>
  );
};
export default DHeader;
