import DashboardPage from "../pages/dashboard/DashboardPage.jsx";
import ErrorPage from "../components/common/ErrorPage.jsx";
import CopyDashboard from "../pages/copyDashboard/CopyDashboard.jsx";
import { BASE_LINK } from "./public.routes.jsx";
import HomePage from "../pages/public/HomePage.jsx";
import FyersAccountCallback from "../forms/entities/broker/components/FyersAccountCallback.jsx";

export const DASHBOARD_LINKS = Object.freeze({
  HOME: BASE_LINK.BASE,
  DASHBOARD: BASE_LINK.BASE + "dashboard",
  COPY_DASHBOARD: BASE_LINK.BASE + "cp-dashboard",
  FYERS_CALLBACK: BASE_LINK.BASE + "fyers-callback",
});

export const dashboardRoutes = [
  {
    name: "Home",
    path: `${DASHBOARD_LINKS.HOME}`,
    element: <HomePage />,
  },
  {
    name: "Copy Trade",
    path: `${DASHBOARD_LINKS.DASHBOARD}`,
    element: <DashboardPage />,
  },
  {
    name: "Copy Dashboard",
    path: `${DASHBOARD_LINKS.COPY_DASHBOARD}`,
    element: <CopyDashboard />,
  },
  {
    name: "",
    path: `${DASHBOARD_LINKS.FYERS_CALLBACK}`,
    element: <FyersAccountCallback />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
];
