import { Card, Image, Text } from "@mantine/core";
import { services } from "./services.constant.js";

const ServiceCard = ({ limit = false, limitCount = 4 }) => {
  const offeredServices = limit ? services.slice(0, limitCount) : services;

  return offeredServices.map((service, i) => {
    return (
      <Card key={i} p="xl" shadow="sm" radius={0} className="flex justify-center text-center">
        <div className="mb-5 flex justify-center text-center">
          <Image w={80} src={service.image} alt={service.name} />
          {/* <div className="p-10 border rounded-full">
          <span className="iconify" data-icon="\e908"></span>
          </div> */}
        </div>
        <Text fz="xl" mb={10} lineClamp={1} fw="bold" component="h3">
          {service.name}
        </Text>
        <Text fz="md" lineClamp={4} component="p">
          {service.info}
        </Text>
      </Card>
    );
  });
};

export default ServiceCard;
