import { IconGps, IconMessage2, IconPhone } from "@tabler/icons-react";
const contactData = [
  {
    icon: <IconGps stroke={1} width={50} height={50} />,
    title: "Location",
    info: "39 - Maganlalpark, Vitthalnagar cross road, Shahibag Ahmedabad",
  },
  {
    icon: <IconMessage2 stroke={1} width={50} height={50} />,
    title: "Email",
    info: "info@occbot.com",
  },
  {
    icon: <IconPhone stroke={1} width={50} height={50} />,
    title: "Phone",
    info: "+91 91069 52672",
  },
];
const ContactInfo = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-10">
      {contactData.map((item, index) => {
        const isLastIndex = index === contactData.length - 1;
        return (
          <div
            key={index}
            className="flex items-start gap-5 bg-white shadow-sm drop-shadow-sm hover:shadow-xl transition-shadow p-5"
          >
            <div className="text-[var(--mantine-primary-color-filled)]">
              {item.icon}
            </div>
            <div>
              <h3 className="text-lg mb-2">{item.title}</h3>
              <p className="text-lg">
                {item.info}
              </p>
              {
                isLastIndex && (
                  <>
                    <p className="text-lg py-1">+91 88495 75823</p>
                    <p className="text-lg">+91 93163 27260</p>
                  </>
                )
              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContactInfo;
