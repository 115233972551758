import AddChildAccountForm from "../../forms/entities/accounts/AddChildAccountForm";
import DeleteMasterAccountModal from "../../forms/entities/accounts/DeleteMasterAccountModal";
import PopupContext from "../popup/PoupContext";
import GlobalTableNew from "../table/GlobalTable1";
import ActionButton from "../table/general/action/ActionButton";
import ActionButtonWrapper from "../table/general/action/ActionButtonWrapper";
import ChildAccountListColumns from "./columns/ChildAccountListColumns";

const ChildAccountList = () => {
  return (
    <GlobalTableNew
      columns={ChildAccountListColumns}
      endpoint="/broker/get-child-accounts/"
      tableProps={{
        // enableFilters: false,
        enableFullScreenToggle: false,
        enableGrouping: false,
        renderRowActions: ({ row }) => {
          return (
            <ActionButtonWrapper>
              <ActionButton
                edit={{
                  isPopup: true,
                  popupTitle: `Update Child Account - ${row.original.broker_id}`,
                  component: <AddChildAccountForm accountData={row.original} />,
                }}
                remove={{
                  isPopup: true,
                  popupTitle: "Delete Child Account",
                  component: (
                    <DeleteMasterAccountModal accountData={row.original} />
                  ),
                }}
              />
            </ActionButtonWrapper>
          );
        },
      }}
      rightAction={
        <PopupContext
          component={<AddChildAccountForm />}
          modelTitle="Add Child Account"
          buttonLabel="Add Child Account"
        />
      }
    />
  );
};

export default ChildAccountList;
