import { Text } from "@mantine/core";
import MasterAccountList from "../../components/dashboard/MasterAccountList";
import AccountDetails from "../../components/dashboard/AccountDetails";
import PopupContext from "../../components/popup/PoupContext.jsx";
import AddMasterAccountForm from "../../forms/entities/accounts/AddMasterAccountForm.jsx";
import ChildAccountList from "../../components/dashboard/ChildAccountList";

const CopyDashboard = () => {
  return (
    <section>
      <div className="md:container">
        <div className="grid grid-cols-1 py-10">
          <div className="w-full max-w-[850px] mx-auto">
            <AccountDetails />
          </div>
        </div>
      </div>

      <div className="grid grid-rows-1 gap-10">
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-between gap-5 mb-4">
            <Text component="h3" fz={20} lineClamp={1} fw="bold">
              Master Account(s)
            </Text>
            <PopupContext
              modelTitle="Add Master Account"
              buttonLabel="Add Master Account"
              component={<AddMasterAccountForm />}
            />
          </div>
          <MasterAccountList />
        </div>
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-between mb-4">
            <Text component="h3" fz={20} lineClamp={1} fw="bold">
              Child Account(s)
            </Text>
          </div>
          <ChildAccountList />
        </div>
      </div>
    </section>
  );
};

export default CopyDashboard;
