import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { baseQueryWithRefreshToken } from "../api.service.js";

export const paymentApi = createApi({
  reducerPath: "payment",
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: [
    "GET_PRODUCT_LISTS",
  ],
  endpoints: (builder) => ({
    getProductLists: builder.query({
      query() {
        return {
          url: `/payment/product-list`,
          method: "GET",
          providesTags: ["GET_PRODUCT_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
            return response;
        }
        
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
  }),
});

export const {
  useGetProductListsQuery,
} = paymentApi;
