import { Button, Text } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  forgotPasswordInitial,
  ForgotPasswordValidationSchema,
} from "./validation/auth.validation.js";
import InputText from "../../elements/InputText.jsx";
import { useForgotPasswordMutation } from "../../../services/auth/auth.services.js";
import { toast } from "react-toastify";
import { useState } from "react";
import { Icon } from "@iconify/react";

const ForgotPasswordForm = () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [linkSent, setLinkSet] = useState(false);
  // Form Methods
  const methods = useForm({
    resolver: yupResolver(ForgotPasswordValidationSchema),
    mode: "onBlur",
    defaultValues: forgotPasswordInitial,
  });

  // Handle forgot password form
  const handleForgotPassword = async (value) => {
    await forgotPassword(value)
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success(data?.message);
          setLinkSet(data?.success);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {!linkSent ? (
        <>
        <div className="flex items-center flex-col justify-center pb-10">
          <Text size="xl" fw="bold">Forgot Password</Text>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleForgotPassword)}>
            <div className="grid grid-cols-1 gap-3">
              <InputText
                name="email"
                label="Email Address"
                props={{ type: "email", withAsterisk: true }}
              />
              <div className="mt-3 flex items-center gap-2">
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        </>
      ) : (
        <div className="grid grid-cols-1 gap-3">
          <div className="text-center mb-5">
            <div className="flex items-center justify-center text-green-500">
              <Icon width={70} icon="icon-park-solid:check-one" />
            </div>
            <h3 className="text-2xl my-5">Password Reset Link Sent</h3>
            <h6 className="text-md mb-8">
              A password reset link has been sent to your email address. Kindly
              check and click the link to reset your password.
            </h6>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordForm;
