import InputText from "../../elements/InputText.jsx";
import InputPassword from "../../elements/InputPassword.jsx";
const MotilalOswalBrokerFormInputs = () => {
  return (
    <div className="grid grid-rows-1 gap-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4">
        <InputText
          name="clientId"
          label="Client ID"
          props={{ withAsterisk: true, size: "lg" }}
        />
        <InputPassword
          name="mPin"
          label="mPin"
          props={{ withAsterisk: true, size: "lg" }}
        />
        <InputPassword
          name="totpKey"
          label="TOTP Key"
          props={{ withAsterisk: true, size: "lg" }}
        />
        <InputPassword
          name="apiKey"
          label="API Key"
          props={{ withAsterisk: true, size: "lg" }}
        />
        <InputPassword
          name="secretKey"
          label="Secret Key"
          props={{ withAsterisk: true, size: "lg" }}
        />
        <InputText
          name="mobileNumber"
          label="Mobile Number  (Optional)"
          props={{ withAsterisk: false, size: "lg" }}
        />
      </div>
      <InputText
        name="emailId"
        label="Email ID (Optional)"
        props={{ withAsterisk: false, size: "lg" }}
      />
    </div>
  );
};

export default MotilalOswalBrokerFormInputs;
