import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  RegisterInitialValue,
  RegistrationValidationSchema,
} from "../validation/auth.validation.js";
import {
  useRegisterMutation,
  useSendOTPMutation,
} from "../../../../services/auth/auth.services.js";
import { Button, Text } from "@mantine/core";
import { RegisterContext } from "./RegisterProvider.jsx";
import { useContext } from "react";

import InputText from "../../../elements/InputText.jsx";
import InputPassword from "../../../elements/InputPassword.jsx";
import { toast } from "react-toastify";

const RegisterForm = () => {
  const { setUser, setCanResendOtp } = useContext(RegisterContext);
  const [register, { isLoading }] = useRegisterMutation();
  const [sendOTP] = useSendOTPMutation();

  // Handle Registration Form
  const methods = useForm({
    defaultValues: RegisterInitialValue,
    resolver: yupResolver(RegistrationValidationSchema),
    mode: "onBlur",
  });

  // Register the user
  const handleFormSubmit = async (values) => {
    await register(values)
      .unwrap()
      .then(async (res) => {
        if (res?.success) {
          await sendOTP({
            mobileNumber: `+91${res?.data?.user?.phone}`,
            userId: res?.data?.user?.user_id,
          })
            .unwrap()
            .then(() => {
              setUser(res?.data?.user);
              toast.success("An OTP has been sent to your mobile number");
              setCanResendOtp(true);
            })
            .finally(() => {
              setTimeout(() => {
                setCanResendOtp(false);
              }, 20000);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
    <div className="flex items-center flex-col justify-center pb-10">
        <Text size="xl" fw="bold" pb={5}>Register</Text>
        <div>
          <Text>Sign up now! for 10-Day Free Trial:</Text>
        </div>
    </div>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <div className="grid grid-cols-1 gap-3">
          <InputText
            name="username"
            label="Username"
            props={{ withAsterisk: true }}
          />
          <InputText
            name="email"
            label="Email"
            props={{ type: "email", withAsterisk: true }}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <InputPassword
              name="password"
              label="Password"
              props={{ type: "password", withAsterisk: true }}
            />
            <InputPassword
              name="confirmPassword"
              label="Confirm Password"
              props={{ type: "password", withAsterisk: true }}
            />
          </div>
          <InputText
            name="phone"
            label="Mobile Number"
            props={{ type: "number", withAsterisk: true, maxLength: 10 }}
          />

          <div className="mt-5">
            <Button
              fullWidth
              disabled={!methods.formState.isValid}
              loading={isLoading}
              type="submit"
            >
              Register
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
    </>
  );
};
export default RegisterForm;
