import { Button } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { closeAllModals } from "@mantine/modals";
import {
  useAddBrokerAccountMutation,
  useGetBrokerListsQuery,
  useUpdateBrokerAccountMutation,
} from "../../../services/account/broker.service.js";
import SelectInput from "../../elements/SelectInput.jsx";
import {
  BrokerAccountDefaultValue,
  BrokerAccountSchema,
} from "./brokerAccount.schema.js";
import NoticeToSelectBrokerAccount from "./NoticeToSelectBrokerAccount.jsx";
import { useEffect, useState } from "react";
import { reFetchTableData } from "../../../utils/functions.js";
import AngeloneBrokerFormInputs from "./AngeloneBrokerFormInputs.jsx";
import FyersBrokerFormInputs from "./FyersBrokerFormInputs.jsx";
import ZerodhaBrokerFormInputs from "./ZerodhaBrokerFormInputs.jsx";
import FinvasiaBrokerFormInputs from "./FinvasiaBrokerFormInputs.jsx";
import DhanBrokerFormInputs from "./DhanBrokerFormInputs.jsx";
import FlattradeBrokerFormInputs from "./FlattradeBrokerFormInputs.jsx";
import AliceBlueBrokerFormInputs from "./AliceBlueBrokerFormInputs.jsx";
import ACAgarwalBrokerFormInputs from "./ACAgarwalBrokerFormInputs.jsx";
import MotilalOswalBrokerFormInputs from "./MotilalOswalBrokerFormInputs.jsx";
import { redirectToFyersAuthLink } from "../../../utils/brokers.js";

const AddBrokerAccountForm = ({ brokerData }) => {
  const { data: brokerLists } = useGetBrokerListsQuery();
  const [brokerType, setBrokerType] = useState("");

  const [
    addBrokerAccount,
    { isLoading: isBaAddLoading, isSuccess: isBaSuccess },
  ] = useAddBrokerAccountMutation();

  const [
    updateBrokerAccount,
    { isLoading: isBaUpdateLoading, isSuccess: isBaUpdateSuccess },
  ] = useUpdateBrokerAccountMutation();

  const methods = useForm({
    resolver: yupResolver(BrokerAccountSchema({ brokerType })),
    mode: "onBlur",
    defaultValues: BrokerAccountDefaultValue,
  });

  const brokerIdValue = methods.watch("broker_id");

  const handleBrokerForm = async (values) => {
    const bLists = brokerLists?.server?.data?.find(
      (item) => item.id === +values.broker_id
    );
    const vData = {
      ...values,
      type: bLists?.type,
      id: brokerData ? brokerData?.id : null,
    };

    console.log(" bLists?.type", bLists?.type);

    if (bLists?.type === "fyers") {
      localStorage.setItem("addFyersAccountData", btoa(JSON.stringify(vData)));
      redirectToFyersAuthLink(vData);
    } else {
      if (brokerData?.id) {
        await updateBrokerAccount(vData).then((res) => {
          if (res?.status === 201 || res?.status === 200) {
            closeAllModals();
            reFetchTableData();
          }
        });
      } else {
        await addBrokerAccount(vData).then((res) => {
          console.log("res", res);
          if (
            res?.data?.success ||
            res?.status === 201 ||
            res?.status === 200
          ) {
            closeAllModals();
            reFetchTableData();
          }
          // closeAllModals();
        });
      }
    }
  };

  useEffect(() => {
    if (brokerData) {
      methods.setValue("broker_id", `${brokerData?.broker_id}`);
      methods.setValue("clientId", brokerData?.client_id);
      methods.setValue("mPin", brokerData?.m_pin);
      methods.setValue("totpKey", brokerData?.totp_key);
      methods.setValue("apiKey", brokerData?.api_key);
      methods.setValue("secretKey", brokerData?.secret_key);
      if (brokerData?.phone) {
        methods.setValue("mobileNumber", brokerData?.phone);
      }
      if (brokerData?.email) {
        methods.setValue("emailId", brokerData?.email);
      }

      setBrokerType(
        brokerLists?.server?.data?.find(
          (item) => item.id === +brokerData?.broker_id
        )?.type
      );
    }
  }, [brokerData, brokerLists]);

  useEffect(() => {
    setBrokerType(
      brokerLists?.server?.data?.find((item) => item.id === +brokerIdValue)
        ?.type
    );
  }, [brokerIdValue]);

  const GetBrokerForm = () => {
    switch (brokerType) {
      case "angelone":
        return <AngeloneBrokerFormInputs />;
      case "fyers":
        return <FyersBrokerFormInputs />;
      case "motilal-oswal":
        return <MotilalOswalBrokerFormInputs />;
      case "ac-agarwal":
        return <ACAgarwalBrokerFormInputs />;
      case "alice-blue":
        return <AliceBlueBrokerFormInputs />;
      case "flattrade":
        return <FlattradeBrokerFormInputs />;
      case "dhan":
        return <DhanBrokerFormInputs />;
      case "finvasia":
        return <FinvasiaBrokerFormInputs />;
      case "zerodha":
        return <ZerodhaBrokerFormInputs />;
      default:
        return null;
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleBrokerForm)}>
          <div className='grid grid-cols-1 gap-2'>
            <SelectInput
              label='Broker Accounts'
              name='broker_id'
              data={brokerLists?.dropdown}
              props={{
                withAsterisk: true,
                required: true,
                allowDeselect: false,
                size: "lg",
                nothingFoundMessage: "No broker account found",
              }}
            />
            {brokerIdValue ? (
              <div>
                <GetBrokerForm />

                <div className='mt-5 flex items-center gap-2 gap-x-4'>
                  <Button
                    size='lg'
                    fullWidth
                    type='submit'
                    disabled={
                      !methods.formState.isValid ||
                      isBaAddLoading ||
                      isBaUpdateLoading
                    }
                  >
                    {brokerData ? "Update Account" : "Add Account"}
                  </Button>
                  <Button
                    size='lg'
                    color='black'
                    fullWidth
                    type='button'
                    onClick={closeAllModals}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <NoticeToSelectBrokerAccount />
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddBrokerAccountForm;
