import { ActionIcon, Tooltip } from "@mantine/core";
import { Icon } from "@iconify/react";

const socialAccounts = [
  {
    name: "YouTube",
    link: "https://youtube.com",
    icon: "logos:youtube-icon",
    color: "red.7",
    size: 25,
  },
  {
    name: "Facebook",
    link: "https://facebook.com",
    icon: "logos:facebook",
    size: 25,
    color: "indigo.8",
  },
  {
    name: "Instagram",
    link: "https://instagram.com",
    icon: "skill-icons:instagram",
    color: "yellow.7",
    size: 25,
  },

  {
    name: "Twitter",
    link: "https://twitter.com",
    icon: "logos:twitter",
    color: "blue.7",
    size: 25,
  },
  // {
  //   name: "LinkedIn",
  //   link: "https://linkedin.com",
  //   icon: "devicon:linkedin",
  //   color: "blue.7",
  //   size: 25,
  // },
  // {
  //   name: "Telegram",
  //   link: "https://telegram.com",
  //   icon: "logos:telegram",
  //   color: "teal.8",
  //   size: 25,
  // },
];
const SocialMedia = () => {
  return (
    <div className="flex items-center gap-2">
      {socialAccounts.map((social) => {
        return (
          <Tooltip
            key={social.name}
            label={`Follow on ${social.name}`}
            position="bottom"
            withArrow
            arrowSize={12}
            color={social.color}
            transitionProps={{ transition: "slide-up" }}
          >
            <ActionIcon
              variant="light"
              color={social.color}
              aria-label={social.name}
              size="xl"
              component="a"
              href={social.link}
            >
              <Icon
                icon={social.icon}
                width={social.size}
                height={social.size}
              />
            </ActionIcon>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default SocialMedia;
