import { FormProvider, useForm } from "react-hook-form";
import SwitchInput from "../../../forms/elements/SwitchInput.jsx";
import { reFetchTableData } from "../../../utils/functions.js";
import { useChangeCopyTradeMutation } from "../../../services/account/accounts.service.js";

const MasterAccountTrade = ({accountDetails}) => {
    const [changeCopyTrade] = useChangeCopyTradeMutation();
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            copy_trade: accountDetails?.copy_trade,
        },
    });

    const changeTradeSetting = async (value) => {
        await changeCopyTrade({ copy_trade: value.copy_trade, id: accountDetails?.id });
        reFetchTableData();
    };

    return (
        <FormProvider {...methods}>
            <form onChange={methods.handleSubmit(changeTradeSetting)}>
                <SwitchInput name="copy_trade" props={{ onLabel: "On", offLabel: "Off" }} />
            </form>
        </FormProvider>
    );
};

export default MasterAccountTrade;
