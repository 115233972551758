import Heading from "../../heading/Heading.jsx";
import { Button } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import ServiceCard from "../services/ServiceCard.jsx";

const HomeServices = () => {
  return (
    <section className="py-10">
      <div className="container">
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-center text-center max-w-[650px] mx-auto">
            <Heading
              description="Top Categories"
              title="CATEGORIES"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 my-16">
          <ServiceCard limit limitCount={4} />
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-5 mb-5 sm:max-w-[550px] sm:mx-auto">
          <Button
            fullWidth
            component={Link}
            to="/services"
            variant="light"
            size="lg"
            rightSection={<IconChevronRight stroke={1.5} />}
          >
            Browse Services
          </Button>
          <Button
            fullWidth
            component={Link}
            to="/contact"
            variant="light"
            color="pink"
            size="lg"
            rightSection={<IconChevronRight stroke={1.5} />}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;
