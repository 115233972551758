import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { GET_AUTH_CONFIG } from "../redux/auth/config.slice.js";
import { authRouteLinks } from "../routes/auth.routes.jsx";
import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import DHeader from "../components/common/DHeader.jsx";
import Footer from "../components/common/Footer.jsx";
import MSidebar from "../components/common/MSidebar.jsx";

const DashboardLayout = () => {
  const [opened, { toggle }] = useDisclosure();
  const { isLoggedIn } = useSelector(GET_AUTH_CONFIG);

  if (!isLoggedIn) {
    return <Navigate to={authRouteLinks.login.path} />;
  }
  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { desktop: true, mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header className="shadow drop-shadow-md" withBorder={false}>
        <DHeader opened={opened} toggle={toggle} />
      </AppShell.Header>

      <AppShell.Navbar p="md" px={4} zIndex={0}>
        <MSidebar />
      </AppShell.Navbar>

      <AppShell.Main mih="100%" className="bg-gray-50">
        <Outlet />
      </AppShell.Main>
      <Footer />
    </AppShell>
  );
};
export default DashboardLayout;
