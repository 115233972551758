import React from "react";
import { Button } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  resetPasswordInitial,
  ResetPasswordValidationSchema,
} from "./validation/auth.validation.js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../../services/auth/auth.services.js";
import InputPassword from "../../elements/InputPassword.jsx";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { authRouteLinks } from "../../../routes/auth.routes.jsx";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
  const token = searchParams.get("token");
  // Form Methods

  const methods = useForm({
    resolver: yupResolver(ResetPasswordValidationSchema),
    mode: "onBlur",
    defaultValues: resetPasswordInitial, // set default value
  });

  const handleResetPassword = async (value) => {
    await resetPassword({ token: token, password: value.password })
      .unwrap()
      .then((response) => {
        console.log("response", response);
        if (response?.success) {
          toast.success(response?.message);
          setTimeout(() => {
            navigate("/login");
          }, 5000);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <>
      {!isSuccess ? (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleResetPassword)}>
            <div className="grid grid-cols-1 gap-3">
              <InputPassword
                name="password"
                label="New Password"
                props={{ withAsterisk: true }}
              />
              <InputPassword
                name="confirmPassword"
                label="Confirm New Password"
                props={{ withAsterisk: true }}
              />
              <div className="mt-3 flex items-center gap-2">
                <Button
                  color="green"
                  type="submit"
                  fullWidth
                  disabled={isLoading || !methods.formState.isValid}
                >
                  Change Password
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <div className="grid grid-cols-1 gap-3">
          <div className="text-center mb-5">
            <div className="flex items-center justify-center text-green-500">
              <Icon width={70} icon="icon-park-solid:check-one" />
            </div>
            <h3 className="text-2xl my-5 text-green-500">
              Password Changed Successfully!
            </h3>
            <h6 className="text-xl mb-8">
              Your password has been changed successfully, You can now login
              with the new password.
            </h6>

            <Button
              component={Link}
              to={authRouteLinks.login.path}
              type="submit"
            >
              Go to Login
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPasswordForm;
