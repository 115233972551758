import { Button } from "@mantine/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { closeAllModals } from "@mantine/modals";

import SelectInput from "../../elements/SelectInput.jsx";
import {
  useAddMasterAccountMutation,
  useGetAllAccountListsQuery,
  useGetAllMasterAccountListsQuery,
  useUpdateMasterAccountMutation,
} from "../../../services/account/accounts.service.js";
import SwitchInput from "../../elements/SwitchInput.jsx";
import InputText from "../../elements/InputText.jsx";
import {
  ChildAccountDefaultValue,
  ChildAccountSchema,
} from "./childAccount.schema.js";
import { useEffect } from "react";
import { reFetchTableData1 } from "../../../utils/functions.js";

const AddChildAccountForm = ({ accountData }) => {
  const { data: accountLists } = useGetAllAccountListsQuery();

  const { data: masterAccounts } = useGetAllMasterAccountListsQuery();

  console.log('masterAccounts', masterAccounts);
  const [addMasterAccount, { isLoading: isBaAddLoading }] =
    useAddMasterAccountMutation();

  const [updateMasterAccount, { isLoading: isBaUpdateLoading }] =
    useUpdateMasterAccountMutation();

  const methods = useForm({
    resolver: yupResolver(ChildAccountSchema),
    mode: "onBlur",
    reValidateMode: 'onChange',
    defaultValues: accountData || ChildAccountDefaultValue,
  });

  const handleBrokerForm = async (values) => {
    const vData = {
      ...values,
      accountType: "child"
    };

    if (accountData?.id) {
      await updateMasterAccount(vData).then((res) => {
        if (res?.data?.status || res?.status === 201 || res?.status === 200) {
          closeAllModals();
        }
        closeAllModals();
        reFetchTableData1();
      });
    } else {
      await addMasterAccount(vData).then((res) => {
        if (res?.data?.status || res?.status === 201 || res?.status === 200) {
          closeAllModals();
        }
        closeAllModals();
        reFetchTableData1();
      });
    }
  };

  useEffect(() => {
    if (accountData) {
      methods.setValue("clientId", `${accountData?.broker_id}`);
      methods.setValue("trade", `${accountData?.trade}`);
      methods.setValue("quantity", `${accountData?.quantity}`);
      methods.setValue("quantityType", `${accountData?.quantity_type}`);
    }
  }, [accountData]);

  return (
    <div>
      {methods?.errors}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleBrokerForm)}>
          <div className="grid grid-cols-1 gap-2">
            <SelectInput
              label="Select Master Account"
              name="parent_id"
              data={masterAccounts?.dropdown}
              props={{
                withAsterisk: true,
                required: true,
                allowDeselect: false,
                size: "lg",
                nothingFoundMessage: "No account found",
              }}
            />
            <SelectInput
              label="Select account to add"
              name="clientId"
              data={accountLists?.dropdown}
              props={{
                withAsterisk: true,
                required: true,
                allowDeselect: false,
                size: "lg",
                nothingFoundMessage: "No account found",
              }}
            />
            <InputText
              name="quantity"
              label="Multiplier"
              props={{ type: "text", withAsterisk: true }}
            />
            <div className="mt-4">
              <SwitchInput name="trade" label="Trade" />
            </div>
            <div className="mt-5 flex items-center gap-2 gap-x-4">
              <Button
                size="lg"
                fullWidth
                type="submit"
                disabled={
                  !methods.formState.isValid ||
                  isBaAddLoading ||
                  isBaUpdateLoading
                }
              >
                {accountData ? "Update" : "Add Account"}
              </Button>
              <Button
                size="lg"
                color="black"
                fullWidth
                type="button"
                onClick={closeAllModals}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddChildAccountForm;
