import { Paper, Table } from "@mantine/core";
import MasterTableActionButton from "../../forms/entities/accounts/MasterTableActionButton";
import ShowAllChildpnl from "../dashboard/component/ShowAllChildpnl.jsx";
import DownloadChildOrderBook from "../dashboard/component/DownloadChildOrderBook.jsx";
import ExitAllChildPositions from "../dashboard/component/ExitAllChildPositions.jsx";
import MasterAccountTrade from "../dashboard/component/MasterAccountTrade.jsx";

const MasterAccountTable = ({ data, refetch }) => {
  const rows = data?.map((account) => (
    <Table.Tr key={account?.id}>
      <Table.Td fw="bold">{account?.broker_id}</Table.Td>
      <Table.Td ta="center">
        <div className="flex items-center justify-center">
          <MasterAccountTrade accountDetails={account} />
        </div>
      </Table.Td>
      {/* <Table.Td ta="center">
        <ExitAllChildPositions />
      </Table.Td> */}
      <Table.Td ta="center">
        <DownloadChildOrderBook accountData={account} />
      </Table.Td>
      <Table.Td ta="center">
        <ShowAllChildpnl accountData={account} />
      </Table.Td>
      <Table.Td ta="center">
        <MasterTableActionButton account={account} refetch={refetch} />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Paper shadow="xs" className="overflow-hidden">
      {/*  h={325} */}
      <Table.ScrollContainer miw={300}>
        <Table
          withRowBorders
          withColumnBorders
          highlightOnHover
          striped
          verticalSpacing="md"
          horizontalSpacing="sm"
          stickyHeader
          styles={{
            thead: {
              boxShadow: "var(--mantine-shadow-xs)",
              background: "var(--mantine-color-default)",
              zIndex: 1,
            },
            th: {
              fontSize: "var(--mantine-font-size-md)",
            },
          }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Account</Table.Th>
              <Table.Th ta="center">Copy</Table.Th>
              {/* <Table.Th ta="center">Exit Position</Table.Th> */}
              <Table.Th ta="center">Download</Table.Th>
              <Table.Th ta="center">Show</Table.Th>
              <Table.Th ta="center"></Table.Th>
            </Table.Tr>
          </Table.Thead>
          {
            data?.length > 0 ? (
              <Table.Tbody>{rows}</Table.Tbody>
            ): (
              <Table.Tbody>
                <Table.Td colSpan={6}><div className="flex text-center justify-center">No record found</div></Table.Td>
              </Table.Tbody>
            )
          }
        </Table>
      </Table.ScrollContainer>
    </Paper>
  );
};

export default MasterAccountTable;
