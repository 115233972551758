import { Avatar, Menu, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { GET_AUTH_USER } from "../../redux/auth/user.slice.js";
import { IconLogout } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../services/auth/auth.services.js";
import { authRouteLinks } from "../../routes/auth.routes.jsx";

const UserMenu = () => {
  const user = useSelector(GET_AUTH_USER);

  const [logout] = useLogoutMutation();
  const navigate = useNavigate();

  const doLogout = async () => {
    await logout();
    navigate(authRouteLinks.login.path);
  };

  return (
    <Menu width={200} shadow="sm" transitionProps={{transition: "pop-bottom-right", duration: 200}} withArrow arrowOffset={20} arrowSize={10} position="right-start">
      <Menu.Target>
        <Avatar
          size="50px"
          color="blue"
          className="cursor-pointer uppercase"
          alt={`${user?.username}`}
        >
          {user?.username?.charAt(0)}
        </Avatar>
      </Menu.Target>

      <Menu.Dropdown>
        <div className="p-4 border-b mb-1 flex flex-col w-full">
          <span className="font-bold text-xs">Welcome!</span>
          <Text
            lineClamp={1}
            component="div"
            fw="bold"
            fz={18}
            className="capitalize"
          >
            {user?.username}
          </Text>
        </div>
        <Menu.Item leftSection={<IconLogout stroke={1.5} />} onClick={doLogout}>
          <span className="font-bold">Logout</span>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
