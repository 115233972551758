import React from "react";
import PopupContext from "../../../popup/PoupContext";

const RemoveButton = ({ remove }) => {
  const { icon = "ph:trash", label = "", isPopup = false } = remove;
  return (
    <>
      <div
        key={`act-remove-${JSON.stringify(remove.popupTitle)}`}
        className={`btn-act ${remove.customClass}`}
      >
        {isPopup && (
          <PopupContext
            buttonLabel={label}
            buttonIcon={icon}
            modelTitle={remove.popupTitle}
            component={remove.component}
            customClass={`!p-1 w-0 !h-auto ${remove.customClass}`}
            variant="subtle"
            size="md"
          />
        )}
      </div>
    </>
  );
};

export default RemoveButton;
