"use client";
import { useMemo, useRef, useState } from "react";
import "mantine-react-table/styles.css";
import {
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { Box, MantineProvider, ScrollArea, Text } from "@mantine/core";
import { tableConfigCustom } from "./utils/table.config";
import { tableTheme } from "./theme/tableTheme";
import useGetData from "../../hooks/useGetData";
import TableToolbarInternalActions from "./components/TableToolbarInternalActions";
import TableNoData from "./general/action/TableNoData";


const GlobalTableNew = ({
  columns = [],
  endpoint = "",
  sortBy = "id",
  tableProps,
  rightAction = null,
}) => {
  const tableContainerRef1 = useRef(null);
  const columnsKeyNew = useMemo(() => columns, []);

  const [sorting, setSorting] = useState(
    [{ desc: true, id: sortBy }] || [],
  );
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] =
    useState(
      Object.fromEntries(
        columns.map(({ accessorKey }) => [accessorKey, "contains"]),
      ),
    );
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const {
    data: tableDataNew,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetData({
    endpoint,
    globalFilter,
    sorting,
    pagination,
    columnFilterFns,
    columnFilters
  });

  const totalRowCount = tableDataNew?.total ?? 0;

  const tableOptionsNew = {
    columns: columnsKeyNew,
    data: tableDataNew?.data ?? [],
    rowCount: totalRowCount,
    mantineLoadingOverlayProps: {
      visible: isLoading || isFetching,
      loaderProps: {
        type: "dots",
      },
    },
    state: {
      columnFilterFns,
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
      showAlertBanner: isError,
      showProgressBars: false,
      showLoadingOverlay: isFetching,
      showSkeletons: isFetching,
    },
    onPaginationChange: setPagination,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    mantineTableContainerProps: ({ table }) => ({
      ref: tableContainerRef1,
      component: ScrollArea,
      style: {
        height: table.getState().isFullScreen
          ? "calc(100vh - var(--app-shell-header-height) - 10px)"
          : "calc(100vh - var(--app-shell-header-height) - 300px)",
      },
    }),
    // renderTopToolbarCustomActions: () => <TableHeading heading={heading} />,
    renderToolbarInternalActions: ({ table }) => {
      return (
        <TableToolbarInternalActions
          rightAction={rightAction}
          table={table}
          refetch={refetch}
          id={"table-fetch-new"}
        />
      );
    },
    renderEmptyRowsFallback: ({ table }) => {
        return (
            <Box
                sx={{
                    height: table.getState().isFullScreen
                        ? 'calc(100vh - var(--mantine-header-height) - var(--mantine-header-height) - 80px)'
                        : 'calc(100vh - var(--mantine-header-height) - var(--mantine-header-height) - 243px)'
                }}
            >
                <TableNoData />
            </Box>
        );
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: "red",
          children: (
            <Text
              fw="bold"
              fz="md"
              c="red"
              className="flex items-center justify-center w-full"
            >
              Error loading data from server
            </Text>
          ),
          className:
            "[&_.inexture-Alert-message]:mt-0 [&_.inexture-Alert-message>.mantine-Flex-root]:bg-red-500",
        }
      : {
          className: "[&_.mantine-Flex-root]:px-0",
        },
    mantinePaperProps: ({ table }) => ({
      shadow: "xs",
      withBorder: false,
      style: {
        zIndex: table.getState().isFullScreen ? 102 : 100,
      },
    }),
    ...tableConfigCustom,
    ...tableProps,
  };

  const tableIns = useMantineReactTable(tableOptionsNew);

  return (
    <MantineProvider theme={tableTheme}>
      <MantineReactTable table={tableIns} />
    </MantineProvider>
  );
};

export default GlobalTableNew;
