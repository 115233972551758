import { Image, Progress, Text } from "@mantine/core";
import { useGetAccountsListQuery } from "../../services/account/broker.service";
import userImg from "../../assets/image/users.svg";
import userGroupImg from "../../assets/image/users_group.svg";

const AccountDetails = () => {
  const { data: accountLists, isLoading } = useGetAccountsListQuery();

  const accounts = [
    {
      name: "Master Account",
      icon: (
        <div className="bg-white shadow drop-shadow-sm w-[80px] h-[80px] rounded-full inline-flex items-center justify-center">
          <Image w={50} src={userGroupImg} alt="Master Account" />
        </div>
      ),
      progress: (
        <Progress
          size="xl"
          color="green"
          value={
            (100 / accountLists?.data?.masterAccountCapacity) *
            accountLists?.data?.masterAccount
          }
        />
      ),
      count: isLoading ? 'Loading...' : `${accountLists?.data?.masterAccount} of ${accountLists?.data?.masterAccountCapacity} capacity`,
    },
    {
      name: "Total Account",
      icon: (
        <div className="bg-white shadow drop-shadow-sm w-[80px] h-[80px] rounded-full inline-flex items-center justify-center">
          <Image w={50} src={userImg} alt="Total Account" />
        </div>
      ),
      progress: (
        <Progress
          size="xl"
          color="green"
          value={
            (100 / accountLists?.data?.accountsCapacity) *
            accountLists?.data?.accounts
          }
        />
      ),
      count: isLoading ? 'Loading...' : `${accountLists?.data?.accounts} of ${accountLists?.data?.accountsCapacity} capacity`,
    },
  ];

  return (
    <div className="grid md:grid-cols-2 gap-10">
      {accounts?.length > 0 &&
        accounts?.map((item) => {
          return (
            <div
              className="flex items-center gap-5 p-5 bg-[var(--mantine-color-default)] shadow drop-shadow-sm rounded"
              key={item?.name}
            >
              <div className="flex items-center justify-center">
                {item.icon}
              </div>
              <div className="w-[calc(100%-100px)]">
                <Text fw="bold" fz="xl">
                  {item.name}
                </Text>
                <Text fw="bold" fz={14}>
                  {item.count}
                </Text>
                <div className="mt-2">{item.progress}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AccountDetails;
