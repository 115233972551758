import ErrorPage from "../components/common/ErrorPage.jsx";
import HomePage from "../pages/public/HomePage.jsx";
import ServicePage from "../pages/public/ServicePage.jsx";
import ContactPage from "../pages/public/ContactPage.jsx";
import PlanPage from "../pages/public/PlanPage.jsx";
import {
  IconAlignBoxCenterMiddle,
  IconCurrencyRupee,
  IconHomeInfinity,
  IconMail,
} from "@tabler/icons-react";
import AboutUsPage from "../pages/public/AboutUsPage.jsx";

export const BASE_LINK = Object.freeze({
  BASE: "/",
});

export const PUBLIC_LINKS = Object.freeze({
  SERVICES: BASE_LINK.BASE + "services",
  ABOUTUS: BASE_LINK.BASE + "about",
  CONTACT: BASE_LINK.BASE + "contact",
  PLANS: BASE_LINK.BASE + "plans",
});

export const publicRoutes = [
  {
    name: "Home",
    path: `${BASE_LINK.BASE}`,
    element: <HomePage />,
    icon: <IconHomeInfinity />,
  },
  {
    name: "Services",
    path: `${PUBLIC_LINKS.SERVICES}`,
    element: <ServicePage />,
    icon: <IconAlignBoxCenterMiddle />,
  },
  {
    name: "Contact",
    path: `${PUBLIC_LINKS.CONTACT}`,
    element: <ContactPage />,
    icon: <IconMail />,
  },
  {
    name: "Plans",
    path: `${PUBLIC_LINKS.PLANS}`,
    element: <PlanPage />,
    icon: <IconCurrencyRupee />,
  },
  {
    name: "About Us",
    path: `${PUBLIC_LINKS.ABOUTUS}`,
    element: <AboutUsPage />,
    icon: <IconAlignBoxCenterMiddle />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
];
