import * as Yup from "yup";

const brokerSpecificValidation = (brokerType, field, requiredFields) => {
  return requiredFields.includes(brokerType) ? field.required(`${field.label} is required`) : field.nullable();
};

export const BrokerAccountSchema = ({ brokerType }) => {
  const commonFields = {
    broker_id: Yup.string().required("Please select a broker account"),
    mobileNumber: Yup.number().optional(),
    emailId: Yup.string().email().optional(),
    yearOfBirth: Yup.string(),
  };

  const brokerSpecificFields = {
    clientId: brokerSpecificValidation(brokerType, Yup.string(), ['angelone', 'fyers', 'zerodha', 'finvasia', 'dhan', 'flattrade']),
    mPin: brokerSpecificValidation(brokerType, Yup.string(), ['angelone', 'fyers']),
    totpKey: brokerSpecificValidation(brokerType, Yup.string(), ['angelone', 'fyers', 'zerodha', 'finvasia', 'flattrade']),
    apiKey: brokerSpecificValidation(brokerType, Yup.string(), ['angelone', 'fyers', 'zerodha', 'finvasia', 'flattrade']),
    secretKey: brokerSpecificValidation(brokerType, Yup.string(), ['angelone', 'fyers', 'zerodha', 'flattrade']),
    password: brokerSpecificValidation(brokerType, Yup.string(), ['zerodha', 'finvasia', 'flattrade']),
    imeiKey: brokerSpecificValidation(brokerType, Yup.string(), ['finvasia']),
    vendorCode: brokerSpecificValidation(brokerType, Yup.string(), ['finvasia']),
    accessToken: brokerSpecificValidation(brokerType, Yup.string(), ['dhan']),
    clientName: brokerSpecificValidation(brokerType, Yup.string(), ['dhan']),
  };

  return Yup.object().shape({
    ...commonFields,
    ...brokerSpecificFields,
  });
};

export const BrokerAccountDefaultValue = Yup.reach(BrokerAccountSchema).key;