import React from "react";
import BrokerSlider from "../../broker/BrokerSlider.jsx";

const HomeBrokers = () => {
  return (
    <section className="py-10">
      <div className="container">
        <div className="grid grid-cols-1 px-2">
          <h3 className="text-3xl mb-2">BROKERS</h3>
          <p className="text-xl">
            Copy Trade with Angel Broking, IN next phase we will add all Indian Brokers.
          </p>
        </div>
        <div className="grid grid-cols-1 mt-5">
          <BrokerSlider />
        </div>
      </div>
    </section>
  );
};

export default HomeBrokers;
