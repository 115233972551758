import { Button, Text } from "@mantine/core";
import InputText from "../../elements/InputText.jsx";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidationSchema } from "./validation/auth.validation.js";
import { useLoginMutation } from "../../../services/auth/auth.services.js";
import { useEffect } from "react";
import { closeAllModals } from "@mantine/modals";

const LoginForm = () => {
  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const methods = useForm({
    resolver: yupResolver(LoginValidationSchema),
    mode: "onBlur",
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleLoginForm = async (value) => {
    await login(value);
  };

  useEffect(() => {
    if (isSuccess) {
      closeAllModals();
    }
  }, [isSuccess]);

  return (
    <>
    <div className="flex items-center flex-col justify-center pb-10">
        <Text size="xl" fw="bold">Login</Text>
    </div>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleLoginForm)}>
        <div className="grid grid-cols-1 gap-3">
          <InputText
            name="username"
            label="Username"
            props={{ withAsterisk: true }}
          />
          <InputText
            name="password"
            label="Password"
            props={{ type: "password", withAsterisk: true }}
          />
          <div className="mt-5">
            <Button
              fullWidth
              type="submit"
              disabled={!methods.formState.isValid}
              loading={methods.formState.isLoading || isLoading}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
    </>
  );
};
export default LoginForm;
