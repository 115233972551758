import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";
import { baseQueryWithRefreshToken } from "../api.service.js";

export const brokerApi = createApi({
  reducerPath: "broker",
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: [
    "GET_BROKER_LISTS",
    "GET_BROKER_ACCOUNT_LISTS",
    "GET_ACCOUNT_LISTS",
    "GET_ORDER_BOOK_LISTS",
    "GET_PNL_LISTS",
    "GET_POSITION_LISTS",
    "GET_HOLDING_LISTS"
  ],
  endpoints: (builder) => ({
    getBrokerLists: builder.query({
      query() {
        return {
          url: `/broker/get-broker-list`,
          method: "GET",
          providesTags: ["GET_BROKER_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          const dropData = response?.data
            ?.filter((item) => item?.is_active)
            .map((item) => {
              return {
                label: item.name,
                value: `${item.id}`,
              };
            });
          return {
            server: response,
            dropdown: dropData,
          };
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    addBrokerAccount: builder.mutation({
      query(data) {
        return {
          url: `/broker/accounts`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_BROKER_ACCOUNT_LISTS"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    updateBrokerAccount: builder.mutation({
      query(data) {
        return {
          url: `/broker/accounts`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GET_BROKER_ACCOUNT_LISTS"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    getBrokerAccounts: builder.query({
      query() {
        return {
          url: `/broker/get-accounts-list`,
          method: "GET",
          providesTags: ["GET_BROKER_ACCOUNT_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          console.log({ response });
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    deleteBrokerAccount: builder.mutation({
      query: (id) => ({
        url: `/broker/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "GET_BROKER_ACCOUNT_LISTS" }],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    getAccountsList: builder.query({
      query() {
        return {
          url: `/broker/account-lists`,
          method: "GET",
          providesTags: ["GET_ACCOUNT_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          console.log({ response });
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    changeTrade: builder.mutation({
      query(data) {
        return {
          url: `/broker/update-trade-settings/${data.id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_BROKER_ACCOUNT_LISTS"],
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          toast.success(response?.message);
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          if (error?.status === 400) {
            toast.error(error?.data?.message);
          }
          return error;
        }
      },
    }),
    downloadBrokerLists: builder.mutation({
      query() {
        return {
          url: `/broker/download-accounts-list`,
          method: "POST",
          responseHandler: 'text',
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getOrderBookList: builder.query({
      query(id) {
        return {
          url: `/broker/get-child-order-book/${id}`,
          method: "GET",
          providesTags: ["GET_ORDER_BOOK_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getPositionsList: builder.query({
      query(id) {
        return {
          url: `/broker/get-child-position/${id}`,
          method: "GET",
          providesTags: ["GET_POSITION_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getHoldingList: builder.query({
      query(id) {
        return {
          url: `/broker/get-child-holding/${id}`,
          method: "GET",
          providesTags: ["GET_HOLDING_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
    getPNLList: builder.query({
      query(id) {
        return {
          url: `/broker/all-child-positions/${id}`,
          method: "GET",
          providesTags: ["GET_PNL_LISTS"],
        };
      },
      transformResponse: (response, meta) => {
        if (meta.response.ok) {
          return response;
        }
        return response;
      },
      transformErrorResponse(error, meta) {
        if (!meta.response.ok) {
          toast.error(error?.data?.data?.errors?.[0]?.["message"]);
          return error;
        }
      },
    }),
  }),
});

export const {
  useGetBrokerListsQuery,
  useAddBrokerAccountMutation,
  useUpdateBrokerAccountMutation,
  useDeleteBrokerAccountMutation,
  useGetAccountsListQuery,
  useChangeTradeMutation,
  useDownloadBrokerListsMutation,
  useGetBrokerAccountsQuery,
  useGetOrderBookListQuery,
  useGetHoldingListQuery,
  useGetPositionsListQuery,
  useGetPNLListQuery
} = brokerApi;
