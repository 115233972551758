import LoginPage from "../pages/auth/LoginPage.jsx";
import RegisterPage from "../pages/auth/RegisterPage.jsx";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage.jsx";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage.jsx";

export const AUTH_BASE_LINK = Object.freeze({
  BASE: "",
});

export const authRouteLinks = {
  login: {
    name: "Login",
    path: `${AUTH_BASE_LINK.BASE}/login`,
  },
  fPassword: {
    name: "Forgot Password",
    path: `${AUTH_BASE_LINK.BASE}/password/forgot`,
  },
  rPassword: {
    name: "Reset Password",
    path: `${AUTH_BASE_LINK.BASE}/password/reset`,
  },
  register: {
    name: "Register",
    path: `${AUTH_BASE_LINK.BASE}/register`,
  },
};

export const authRoutes = [
  {
    name: authRouteLinks.login.name,
    path: authRouteLinks.login.path,
    element: <LoginPage />,
  },
  {
    name: authRouteLinks.fPassword.name,
    path: authRouteLinks.fPassword.path,
    element: <ForgotPasswordPage />,
  },
  {
    name: authRouteLinks.rPassword.name,
    path: authRouteLinks.rPassword.path,
    element: <ResetPasswordPage />,
  },
  {
    name: authRouteLinks.register.name,
    path: authRouteLinks.register.path,
    element: <RegisterPage />,
  },
];
