import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Select } from "@mantine/core";

const SelectInput = ({
  label,
  name,
  data,
  required = false,
  disabled,
  props,
  placeholder,
  handleChange,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            ref={ref}
            label={label}
            data={data}
            value={value}
            placeholder={placeholder ? placeholder : `Select ${label}`}
            required={required}
            disabled={disabled}
            onChange={(newValue) => {
              onChange(newValue ? newValue : "");
              handleChange && handleChange(newValue ? newValue : "");
            }}
            onBlur={onBlur}
            error={
              errors[name] && (
                <div className="error text-[13px] mt-2">
                  {errors[name].message}
                </div>
              )
            }
            {...props}
          />
        )}
      />
    </div>
  );
};

export default SelectInput;
