import SwitchInput from '../../../forms/elements/SwitchInput';
import { FormProvider, useForm } from 'react-hook-form';
import { reFetchTableData1 } from '../../../utils/functions';
import { useChangeAccountTradeMutation } from '../../../services/account/accounts.service';

const ChildAccountTrade = (cellData) => {
    const accountDetails = cellData?.rowData?.original;
    const [ChangeAccountTrade] = useChangeAccountTradeMutation();
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            trade: accountDetails?.trade,
        },
    });

    const changeTradeSetting = async (value) => {
        await ChangeAccountTrade({ trade: value.trade, id: accountDetails?.id });
        reFetchTableData1();
    };

    return (
        <FormProvider {...methods} >
            <form onChange={methods.handleSubmit(changeTradeSetting)}>
                <SwitchInput name="trade" />
            </form>
        </FormProvider>
    );
}

export default ChildAccountTrade
