import { useState, useEffect } from "react";

const useCountdownTimer = (initialSeconds = 60) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  const startCountdown = () => {
    setIsActive(true);
  };

  const resetCountdown = () => {
    setSeconds(initialSeconds);
    setIsActive(false);
  };

  useEffect(() => {
    let intervalId;

    if (isActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      setIsActive(false);
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, seconds, initialSeconds]);

  const minutes = Math.floor(seconds / 60)
    ?.toString()
    .padStart(2, "0");
  const remainingSeconds = (seconds % 60)?.toString().padStart(2, "0");

  return {
    minutes,
    seconds: remainingSeconds,
    isActive,
    startCountdown,
    resetCountdown,
  };
};

export default useCountdownTimer;
