import { IconDownload } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { downloadExcelFile } from "../../../services/account/download.service";

const DownloadData = () => {
  const downloadAccountList = async () => {
    await downloadExcelFile('broker/download-accounts-list', 'POST', 'accounts.xlsx');
  }
  return (
    <Button variant="light" leftSection={<IconDownload />} onClick={downloadAccountList}>
      Download
    </Button>
  );
};

export default DownloadData;
